import React from 'react';

import {data_get} from "../../utils/data_get";
import {formatCurrency} from '../../utils/formatCurrency';
import {formatPercentage} from '../../utils/formatPercentage';

const BonusOverviewBreakdown = (props) => {

    const racing_breakdown = props.results.racing_breakdown ? props.results.racing_breakdown : {};
    const sports_breakdown = props.results.sports_breakdown ? props.results.sports_breakdown : {};
    const multi_breakdown = props.results.multi_breakdown ? props.results.multi_breakdown : {};
    const bonus_breakdown = props.results.bonus_breakdown ? props.results.bonus_breakdown : {};    

    // Singles
    let singleRacingBonus = data_get(racing_breakdown, 'bonus');
    let singleSportsBonus = data_get(sports_breakdown, 'bonus');

    let singleRacingBonusResults =  data_get(racing_breakdown, 'bonus_result');
    let singleSportsBonusResults =  data_get(sports_breakdown, 'bonus_result');

    // Multi
    let multiRacingBonus = data_get(multi_breakdown.racing, 'bonus');
    let multiRacingBonusResult = data_get(multi_breakdown.racing, 'bonus_result');

    let multiSportBonus = data_get(multi_breakdown.sport, 'bonus');
    let multiSportBonusResult = data_get(multi_breakdown.sport, 'bonus_result');

    // Totals
    let racingTotalBonus = singleRacingBonus + multiRacingBonus;
    let sportTotalBonus = singleSportsBonus + multiSportBonus;

    let racingTotalBonusResult = singleRacingBonusResults + multiRacingBonusResult;
    let sportTotalBonusResult = singleSportsBonusResults + multiSportBonusResult;

    // Overall total
    let totalBonus = racingTotalBonus + sportTotalBonus;
    let totalBonusResults = racingTotalBonusResult + sportTotalBonusResult;

  return (
    <div className="p-2 mb-3">
      <table className="table-full-width table-figures table-striped">
        <thead>
          <tr>
            <th className="breakdown-title">Overview Results</th>
            <th className="td-figure">Bonus</th>
            <th className="td-figure">Result</th>
            <th className="td-figure">%</th>
          </tr>
        </thead>
        <tbody>

                    <tr>
                        <td>Racing</td>
                        <td className="td-figure">{formatCurrency(singleRacingBonus)}</td>
                        <td className="td-figure">{formatCurrency(singleRacingBonusResults)}</td>
                        <td className="td-figure">{formatPercentage(singleRacingBonus ? singleRacingBonusResults/singleRacingBonus : 0)}</td>
                    </tr>
                    <tr>
                        <td>Racing Multi</td>
                        <td className="td-figure">{formatCurrency(multiRacingBonus)}</td>
                        <td className="td-figure">{formatCurrency(multiRacingBonusResult)}</td>
                        <td className="td-figure">{formatPercentage(multiRacingBonus ? multiRacingBonusResult/multiRacingBonus : 0)}</td>
                    </tr>
                    <tr className="tr-sub-total">
                        <td>Racing Total</td>
                        <td className="td-figure">{formatCurrency(racingTotalBonus)}</td>
                        <td className="td-figure">{formatCurrency(racingTotalBonusResult)}</td>
                        <td className="td-figure">{formatPercentage(racingTotalBonus ? racingTotalBonusResult/racingTotalBonus : 0)}</td>
                    </tr>
                    <tr>
                        <td>Sport</td>
                        <td className="td-figure">{formatCurrency(singleSportsBonus)}</td>
                        <td className="td-figure">{formatCurrency(singleSportsBonusResults)}</td>
                        <td className="td-figure">{formatPercentage(singleSportsBonus ? singleSportsBonusResults/singleSportsBonus : 0)}</td>
                    </tr>
                    <tr>
                        <td>Sport Multi</td>
                        <td className="td-figure">{formatCurrency(multiSportBonus)}</td>
                        <td className="td-figure">{formatCurrency(multiSportBonusResult)}</td>
                        <td className="td-figure">{formatPercentage(multiSportBonus ? multiSportBonusResult/multiSportBonus : 0)}</td>
                    </tr>
                    <tr className="tr-sub-total">
                        <td>Sport Total</td>
                        <td className="td-figure">{formatCurrency(sportTotalBonus)}</td>
                        <td className="td-figure">{formatCurrency(sportTotalBonusResult)}</td>
                        <td className="td-figure">{formatPercentage(sportTotalBonus ? sportTotalBonusResult/sportTotalBonus : 0)}</td>
                    </tr>

                    <tr className="result-row">
                        <td>Total</td>
                        <td className="td-figure">{ formatCurrency(totalBonus) }</td>
                        <td className="td-figure">{ formatCurrency(totalBonusResults)}</td>
                        <td className="td-figure">{ formatPercentage( totalBonus ? totalBonusResults/totalBonus : 0) }</td>
                    </tr>

        </tbody>
      </table>
    </div>
  );
};

export default BonusOverviewBreakdown;

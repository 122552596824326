import env from '../Environment';

const BASE_FONT_SIZE = env('baseFontSize', 12);

export const scale = (value, type) => {

    var fontSize = 1;

    if(type === 'up') {
        fontSize = parseInt(value) + 1;
    } else if (type === 'down') {
        fontSize = parseInt(value) - 1;
    };

    localStorage.setItem('baseFontSize', fontSize);

    return fontSize;

}

export const scaleToDefault = () => {

    localStorage.setItem('baseFontSize', BASE_FONT_SIZE);

    return BASE_FONT_SIZE;
}
import { createController } from 'redux-entities-reducer';

const Controller = createController();

if (process.env.NODE_ENV !== 'production') {
	window.EntitiesController = Controller;
}

const USER = 'clients';
const RACING_SUMMARY = 'racing_summary';

Controller.register(USER);
Controller.register(RACING_SUMMARY);

export default Controller.init();

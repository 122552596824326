import React from 'react';
import uniqid from 'uniqid';
import {data_get} from '../../utils/data_get';
import {formatPercentage} from '../../utils/formatPercentage';
import {formatCurrency} from '../../utils/formatCurrency';

const BonusBreakdown = (props) => {
    
    const breakdown = props.breakdown;
    
    let totalBonus = 0;
	let totalResult = 0;

	return (
		<div className="p-2 mb-3">
			<table className="table-full-width table-figures table-striped">
				<thead>
					<tr>
						<th className="breakdown-title">{props.title}</th>
						<th className="td-figure">Bonus</th>
						<th className="td-figure">Result</th>
						<th className="td-figure">%</th>
					</tr>
				</thead>
				<tbody>
				{breakdown ? (
					breakdown.map((row, index) => {

                        if(!row){
                            return null;
                        }

						let bonus = data_get(row, 'bonus');
						let result = data_get(row, 'bonus_result');

						totalBonus += bonus;
						totalResult += result;

						return <tr key={uniqid()}>
							<td>{row.name}</td>
							<td className="td-figure">{formatCurrency(bonus)}</td>
							<td className="td-figure">{formatCurrency(result)}</td>
							<td className="td-figure">{formatPercentage(bonus ? result/bonus : 0)}</td>
						</tr>
                     })
				    ) : (
                    <tr>
                        <td colSpan="4" className="text-center">
                            No Data Found
                        </td>
                    </tr>
				)}

                <tr className="result-row">
                    <td>Total</td>
                    <td className="td-figure">{ formatCurrency(totalBonus) }</td>
                    <td className="td-figure">{ formatCurrency(totalResult) }</td>
                    <td className="td-figure">{ formatPercentage(totalBonus ? totalResult/totalBonus : 0) }</td>
                </tr>
				</tbody>
			</table>
		</div>
	);
};

export default BonusBreakdown;
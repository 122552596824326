import React, {useState} from 'react';
import { Button } from 'react-bootstrap';

const RaceTypeFilter = (props) => {
    const [raceType, setRaceType] = useState(0);

    const handleRaceTypeChange = (selectedRaceType) => {
        setRaceType(selectedRaceType);

        if(props.onChange){
            props.onChange(selectedRaceType);
        }
    }

    return <div className="race-type-filter">
                <div className={'overlay ' + (!props.isLoading ? 'hidden' : '')}></div>
                <Button size="sm" variant="secondary-line" className={'mr-2 ' + (raceType === 0 ? 'active' : '')} onClick={() => {handleRaceTypeChange(0)}}>
                    All
                </Button>
                <Button size="sm" variant="secondary-line" className={'mr-2 ' + (raceType === 1 ? 'active' : '')} onClick={() => {handleRaceTypeChange(1)}}>
                    Gallops
                </Button>
                <Button size="sm" variant="secondary-line"  className={'mr-2 ' + (raceType === 2 ? 'active' : '')} onClick={() => {handleRaceTypeChange(2)}}>
                    Greyhounds
                </Button>
                <Button size="sm" variant="secondary-line"  className={'mr-2 ' + (raceType === 3 ? 'active' : '')} onClick={() => {handleRaceTypeChange(3)}}>
                    Harness
                </Button>
            </div>;
}

export default RaceTypeFilter;
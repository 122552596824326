// Libraries
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Container} from "react-bootstrap";
import env from '../Environment';
import { cancellableToken } from '../common/ajax';

// Actions
import {getSportsSummary} from "../store/entitites/actions/SportActions";
import {fetchTopClients} from "../store/entitites/actions/ClientActions";

// Components
import PeriodFilter from "../components/Filters/PeriodFilter";
import SportBreakdown from "../components/ScoreCard/Sport/SportBreakdown";
import SportScoreCard from "../components/ScoreCard/Sport/SportScoreCard";
import Loading from "../components/Elements/Loading";
import PageLoading from "../components/Elements/PageLoading";
import RefreshButton from "../components/Common/RefreshButton";
import RefreshToggleButton from "../components/Common/RefreshToggleButton";
import FiltersButton from "../components/Common/FiltersButton";

// Utils
import {current_date} from '../utils/date';
import {normalizeBreakdown, normalizeSummary} from "../utils/figures";
import { commaSeparate } from '../utils/numbers';

let source;
let timeout;

const SportsPage = (props) => {

    if( ! localStorage.getItem('authHeader')){
        window.location = '/login';
    }

    /** State **/

    //-- Loading
    const [isLoading, setIsLoading] = useState(false);

    //-- Data
    const [sportsSummary, setSportsSummary] = useState([]);
    const [sportsBreakdown, setSportsBreakdown] = useState([]);

    //-- Clients
    const [sportsTopClients, setSportsTopClients] = useState([]);
    const [clientType, setClientType] = useState('winners');
    const [clientLimit, setClientLimit] = useState(10);
    const client = useSelector(state => state.entities.client);

    //-- filters
    const [periodFilterKey, setPeriodFilterKey] = useState('date');
    const [periodFilterValue, setPeriodFilterValue] = useState(current_date());
    const [showFilters, setShowFilters] = useState(false);

    //-- auto refresh
    const refreshEvery = env('refreshEvery', 30) * 1000;
    const [refresh, setRefresh] = useState(false);

    /**
     * useEffect hook
     */
    useEffect(() => {
        clearTimeout(timeout);

        if(localStorage.getItem('autorefresh') === 'true') {
            setRefresh(true);
        } else {
            setRefresh(false);
        }

        loadPageData(periodFilterKey, periodFilterValue, clientType, clientLimit, client);
    }, [periodFilterKey, periodFilterValue, refresh, client]);

    useEffect(() => {
        if(refresh) {
            clearTimeout(timeout);
            setIsLoading(true)
            loadPageData(periodFilterKey, periodFilterValue, clientType, clientLimit, client);
        }
    }, [clientType, clientLimit]);


    /**
     * Fetch all page data
     *
     * @param {string} period
     * @param {string} value
     */
    const loadPageData = (period, value, type, limit, client) => {

        if(!refresh) {
            setIsLoading(true);
        }

        // Check and cancel previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel('request');
        }

        source = cancellableToken.source();

        Promise.all([
            // Fetch racing summary
            getSportsSummary(periodFilterKey, periodFilterValue, source, client),

            // Fetch top clients
            fetchTopClients(2, type, limit, periodFilterKey, periodFilterValue, null, source)
                .then((clients) => {
                    setSportsTopClients(clients);
                }),

        ]).then(function (responses) {
            clearTimeout(timeout);
            if(refresh) {
                timeout = setTimeout(() => {
                    loadPageData(period, value, type, limit, client);
                }, refreshEvery);
            }

            let _sportsSummary = responses[0];

            if(!_sportsSummary) {
                return;
            }

            setSportsSummary(normalizeSummary(_sportsSummary, 'sports'));
            setSportsBreakdown(normalizeBreakdown(_sportsSummary, 'sports'));

            setIsLoading(false);
        })
    }

    /**
     * Handle period change
     *
     * @param string period
     * @param string value
     */
    const onPeriodChange = (period, value) => {
        setIsLoading(true);
        setPeriodFilterKey(period);
        setPeriodFilterValue(value);
    }

    /**
     * Handle refresh click
     */
    const onRefreshClick = () => {
        setIsLoading(true);
        loadPageData(periodFilterKey, periodFilterValue, clientType, clientLimit, client);
    }

    /**
     * Handle auto refresh toggle
     */
    const handleAutoRefresh = (e) => {
        if(e.target.checked) {
            localStorage.setItem('autorefresh', true);
            setRefresh(true);
        } else {
            localStorage.setItem('autorefresh', false);
            setRefresh(false);
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
        }
    }

    /**
     * Handle client limit change
     */
    const handleClientLimitChange = (limit) => {
        setClientLimit(limit);
    }

    /**
     * Handle client type change
     */
    const handleClientTypeChange = (type) => {
        setClientType(type);
    }

    /**
     * Toggle display filters
     */
    const onFiltersClick = () => {
        setShowFilters( ! showFilters);
    }

    return (
        <Container className="score-container-xl py-4">
            {/* Filters */}
            <div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-sm-row flex-md-row">
                <PeriodFilter onChange={onPeriodChange} isLoading={isLoading}/>
                <div className="col px-0 d-flex justify-content-between justify-content-md-end justify-content-lg-end align-items-center flex-1">
                    <FiltersButton onClick={onFiltersClick}/>
                    <RefreshButton onClick={onRefreshClick} isLoading={isLoading}/>
                    <RefreshToggleButton onChange={handleAutoRefresh} checked={refresh} />
                </div>
            </div>

            {/* Summary and top clients */}
            <div className="row justify-content-between align-items-start position-relative pl-2 pr-3">
                <PageLoading isLoading={isLoading}/>
                <SportScoreCard title={'Sport'}
                                breakdown={sportsBreakdown}
                                summary={sportsSummary}
                                periodKey={periodFilterKey}
                                periodValue={periodFilterValue}
                                topClients={sportsTopClients}
                                refresh={refresh}
                                clientType={clientType}
                                clientLimit={clientLimit}
                                source={source}
                                onClientTypeChange={handleClientTypeChange}
                                onClientLimitChange={handleClientLimitChange} />
                <SportBreakdown summary={sportsBreakdown}/>
            </div>

        </Container>
    );
}

export default SportsPage;

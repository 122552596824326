// Libraries
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Container} from 'react-bootstrap';
import uniqid from 'uniqid';
import env from '../Environment';
import { cancellableToken } from '../common/ajax';

// Actions
import {getRacingSummary} from '../store/entitites/actions/RacingActions';
import {fetchTopClients} from "../store/entitites/actions/ClientActions";

// Components
import PeriodFilter from '../components/Filters/PeriodFilter';
import ProductScoreCard from "../components/ScoreCard/ProductScoreCard";
import RacingScoreCard from '../components/ScoreCard/Racing/RacingScoreCard';
import PageLoading from '../components/Elements/PageLoading';
import RefreshButton from "../components/Common/RefreshButton";
import RefreshToggleButton from "../components/Common/RefreshToggleButton";
import ScoreMasonry from "../components/Common/ScoreMasonry";
import FiltersButton from "../components/Common/FiltersButton";
import RaceTypeFilter from "../components/Filters/RaceTypeFilter";

// Utils
import {current_date} from '../utils/date';
import {isEmpty} from '../utils/objects';
import {normalizeBreakdown, normalizeSummary} from "../utils/figures";

let source;
let timeout;

const RacePage = (props) => {

    if( ! localStorage.getItem('authHeader')){
        window.location = '/login';
    }

    /** State **/

    //-- Loading
    const [isLoading, setIsLoading] = useState(false);

    //-- Data
    const [racingSummary, setRacingSummary] = useState([]);
    const [racingBreakdown, setRacingBreakdown] = useState([]);

    //-- Clients
    const [racingTopClients, setRacingTopClients] = useState([]);
    const [clientType, setClientType] = useState('winners');
    const [clientLimit, setClientLimit] = useState(10);
    const client = useSelector(state => state.entities.client);

    //-- filters
    const [meetingType, setMeetingType] = useState(null);
    const [periodFilterKey, setPeriodFilterKey] = useState('date');
    const [periodFilterValue, setPeriodFilterValue] = useState(current_date());
    const [showFilters, setShowFilters] = useState(false);

    //-- Components
    const [cards, setCards] = useState([]);

    //-- auto refresh
    const refreshEvery = env('refreshEvery', 30) * 1000;
    const [refresh, setRefresh] = useState(false);

    /**
     * useEffect hook
     */
    useEffect(() => {
        clearTimeout(timeout);

        if(localStorage.getItem('autorefresh') === 'true') {
            setRefresh(true);
        } else {
            setRefresh(false);
        }

        loadPageData(periodFilterKey, periodFilterValue, meetingType, clientType, clientLimit, client);
    }, [meetingType, periodFilterKey, periodFilterValue, refresh, client]);

    useEffect(() => {
        if(refresh) {
            clearTimeout(timeout);
            setIsLoading(true)
            loadPageData(periodFilterKey, periodFilterValue, meetingType, clientType, clientLimit, client);
        }
    }, [clientType, clientLimit]);


    /**
     * Fetch all page data
     *
     * @param {string} period
     * @param {string} value
     */
    const loadPageData = (period, value, meetingType, type, limit, client) => {

        if(!refresh) {
            setIsLoading(true);
        }

        // Check and cancel previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel('request');
        }

        source = cancellableToken.source();

        Promise.all([
            // Fetch racing summary
            getRacingSummary(periodFilterKey, periodFilterValue, meetingType, source, client),

            // Fetch top clients
            fetchTopClients(1, type, limit, periodFilterKey, periodFilterValue, meetingType, source)
                .then((clients) => {
                    setRacingTopClients(clients);
                }),

        ]).then(function (responses) {
            clearTimeout(timeout);
            if(refresh) {
                timeout = setTimeout(() => {
                    loadPageData(period, value, meetingType, type, limit, client);
                }, refreshEvery);
            }

            let _racingSummary = responses[0];
            let _racingBreakdown = [];

            if(!_racingSummary) {
                return;
            }

            _racingSummary = normalizeSummary(_racingSummary, 'product_groups');
            _racingBreakdown = normalizeBreakdown(_racingSummary, 'product_groups');

            let scoreCards = [];

            if(!isEmpty(_racingSummary)){
                _racingSummary.product_groups.map((group) => {
                    if(group){
                        scoreCards.push(<ProductScoreCard key={uniqid()} title={ group.name } breakdown={group.products} summary={group} products={group.products}/>);
                    }
                });

                let betTypes = [_racingSummary.bet_types.betbacks, _racingSummary.bet_types.exotics, _racingSummary.bet_types.multi, _racingSummary.bet_types.srm]

                betTypes.map((group) => {
                    if(group){
                        scoreCards.push(<ProductScoreCard key={uniqid()} title={ group.name } breakdown={[group]} summary={group} products={group.products} noTotal={true}/>);
                    }
                });
            }

            setCards(scoreCards);
            setRacingSummary(_racingSummary);
            setRacingBreakdown(_racingBreakdown);
            setIsLoading(false);
        })
    }

    /**
     * Handle period change
     *
     * @param string period
     * @param string value
     */
    const onPeriodChange = (period, value) => {
        setIsLoading(true);
        setPeriodFilterKey(period);
        setPeriodFilterValue(value);
    }

    /**
     * Handle refresh click
     */
    const onRefreshClick = () => {
        setIsLoading(true);
        loadPageData(periodFilterKey, periodFilterValue, meetingType, clientType, clientLimit, client);
    }

    /**
     * Handle auto refresh toggle
     */
    const handleAutoRefresh = (e) => {
        if(e.target.checked) {
            localStorage.setItem('autorefresh', true);
            setRefresh(true);
        } else {
            localStorage.setItem('autorefresh', false);
            setRefresh(false);
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
        }
    }

    /**
     * Handle client limit change
     */
    const handleClientLimitChange = (limit) => {
        setClientLimit(limit);
    }

    /**
     * Handle client type change
     */
    const handleClientTypeChange = (type) => {
        setClientType(type);
    }

    /**
     * Toggle display filters
     */
    const onFiltersClick = () => {
        setShowFilters( ! showFilters);
    }

    /**
     * Handle race type change
     */
    const onRaceTypeChange = (type) => {
        setMeetingType(type);
    }

	return (
		<Container className="score-container-xl py-4">
			{/* Filters */}
            <div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-sm-row flex-md-row">
                <PeriodFilter onChange={onPeriodChange} isLoading={isLoading}/>
                <div className="col px-0 d-flex justify-content-between justify-content-md-end justify-content-lg-end align-items-center flex-1">
                    <FiltersButton onClick={onFiltersClick}/>
                    <RaceTypeFilter onChange={onRaceTypeChange}  isLoading={isLoading}/>
                    <RefreshButton onClick={onRefreshClick} isLoading={isLoading}/>
                    <RefreshToggleButton onChange={handleAutoRefresh} checked={refresh} />
                </div>
			</div>

			{/* Score cards */}
			<div className="row score-cards position-relative">
                <PageLoading isLoading={isLoading}/>

                <ScoreMasonry cols={4} content={[<RacingScoreCard key="racing-score-card"
                                                                  title={'Racing'}
                                                                  breakdown={racingBreakdown}
                                                                  summary={racingSummary}
                                                                  periodKey={periodFilterKey}
                                                                  periodValue={periodFilterValue}
                                                                  meetingType={meetingType}
                                                                  refresh={refresh}
                                                                  clientType={clientType}
                                                                  clientLimit={clientLimit}
                                                                  source={source}
                                                                  onClientTypeChange={handleClientTypeChange}
                                                                  onClientLimitChange={handleClientLimitChange}
                                                                  topClients={racingTopClients}/>].concat(cards)}/>
			</div>
		</Container>
	);
};


export default RacePage;
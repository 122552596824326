import {getApiUrl} from './utils/url';

export default (key, def) => {
	// Create config object
	const config = {
		apiUrl: getApiUrl(),
		baseFontSize: 12, // pixel value
		minFontSize: 8, // pixel value
		maxFontSize: 20, // pixel value
		refreshEvery: 30, // seconds
    };

	if (!key) {
		return config;
	}

	const value = config[key];

	// NULL !== undefined!!
	if (value === undefined) {
		return def;
	}

	return value;
};

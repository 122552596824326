import {get, wasCancelled} from '../../../common/ajax';
import {buildParams, redirectTo} from "../../../utils/url";
import {toast} from "react-toastify";
import {LOGIN_ROUTE} from "../../../constants/RouteConstants";
import {logout} from "../../application/applicationActions";
import { createAction } from '../../index';
import { SET_CLIENT, REMOVE_CLIENT } from './ActionTypes';

export const fetchTopClients = (eventType=null, type='winners', limit=10, periodKey='date', periodValue, meetingType=null, source, bonus=false) => {

    let params = buildParams({filterKey: periodKey, filterValue: periodValue, meetingType: meetingType, eventType: eventType, bonus: bonus});
    let token = source ? source.token : null;

    params['type'] = type;
    params['limit'] = limit;

    // Bonus
    if(bonus){
        params['bonus_flag'] = bonus;
    }

    return get('/stats/client-summary', { cancelToken: token, params })
        .then((res) => {
            return res.data.data;
        })
        .catch((e) => {
            if (wasCancelled(e)) {
                return;
            }
            toast.error('Error fetching clients summary', {toastId: 'client-toast'});
        });
};

export const getUniqueClientSummary = (filterKey, filterValue, meetingType, source) => {

    let params = buildParams({filterKey: filterKey, filterValue: filterValue, meetingType: meetingType});

    return get('/stats/unique-clients-summary', { cancelToken: source.token, params })
        .then(response => {
            return response.data ? response.data.data : {};
        })
        .catch((e) => {
        if (wasCancelled(e)) {
            return;
        }
        if(e.response && e.response.status == 401){
            logout();
            redirectTo(LOGIN_ROUTE);
        }
        toast.error('Error fetching multi summary', {toastId: 'multi-toast'});
    });
};

/**
 * Set client
 *
 * @param client
 * @returns {Object}
 */
export const setClient = (client) => {
	return createAction(SET_CLIENT, client);
};

/**
 * Remove client
 *
 * @param client
 * @returns {Object}
 */
export const removeClient = () => {
	return createAction(REMOVE_CLIENT);
};

import $ from 'jquery';
import React from "react";
import env from './Environment';
import { urlIs } from './utils/url';
import { ThemeProvider } from "styled-components";
import { scale, scaleToDefault } from './utils/scale'
import { BrowserRouter, Switch } from "react-router-dom";
import {
    BASE_ROUTE,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    MULTI_ROUTE,
    RACING_ROUTE,
    SPORTS_ROUTE,
    UNIQUE_CLIENTS_ROUTE,
    BONUS_ROUTE,
    CHANNEL_SUMMARY_ROUTE,
} from "./constants/RouteConstants";

//theme
import { lightTheme } from "./theme/theme";
import { GlobalStyles } from "./theme/global";

//Components
import PrivateRoute from "./components/Route/PrivateRoute";
import HeaderBar from "./components/Layouts/HeaderBar";

//Pages
import HomePage from "./pages/HomePage";
import RacePage from "./pages/RacePage";
import SportsPage from "./pages/SportsPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import MultiPage from "./pages/MultiPage";
import UniqueClientsPage from "./pages/UniqueClientsPage";
import BonusPage from "./pages/BonusPage";
import ChannelSummaryPage from "./pages/ChannelSummaryPage";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/js/dist/tooltip';
import 'select2/dist/css/select2.min.css';

class App extends React.Component {

  BASE_ROUTE = localStorage.getItem('authHeader') ? DASHBOARD_ROUTE : LOGIN_ROUTE;

  state = {
    baseFontSize: env('baseFontSize', 12),
    disableZoomIn: false,
    disableZoomOut: false,
  }

  handleZoomIn = () => {

    var fontSize = scale(this.state.baseFontSize, 'up');

    this.setState((state) => {
        return {
          ...state,
          baseFontSize: fontSize,
          disableZoomOut: false
        }
    });

    //Max zoom out
    if(parseInt(fontSize) >= env('maxFontSize', 20)) {

      this.setState((state) => {
        return {
          ...state,
          disableZoomIn: true
        }
      });

      return;
    }

  }

  handleZoomOut = () => {

    var fontSize = scale(this.state.baseFontSize, 'down');

    this.setState((state) => {
        return {
          ...state,
          baseFontSize: fontSize,
          disableZoomIn: false
        }
    });

    // Min zoom out
    if(fontSize <= env('minFontSize', 8)) {
      this.setState((state) => {
        return {
          ...state,
          disableZoomOut: true
        }
      });

      return;
    }

  }

  handleReset = () => {

    var fontSize = scaleToDefault();

    this.setState({
      baseFontSize: fontSize,
      disableZoomIn: false,
      disableZoomOut: false
    });

  }

  componentDidMount () {

    if(!urlIs('/login')) {

      if(!localStorage.getItem('baseFontSize')) {
        localStorage.setItem('baseFontSize', env('baseFontSize', 12));
      }

      if(!localStorage.getItem('autorefresh')) {
        localStorage.setItem('autorefresh', false);
      }

      this.setState({
          baseFontSize: localStorage.getItem('baseFontSize'),
          disableZoomIn: parseInt(localStorage.getItem('baseFontSize')) >= env('maxFontSize', 20) ? true : false,
          disableZoomOut: parseInt(localStorage.getItem('baseFontSize')) <= env('minFontSize', 8) ? true : false,
      });

    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  render() {
    return (
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles baseFontSize={this.state.baseFontSize} />
        <HeaderBar
          onClickZoomIn={this.handleZoomIn}
          onClickZoomOut={this.handleZoomOut}
          onClickReset={this.handleReset}
          disableZoomIn={this.state.disableZoomIn}
          disableZoomOut={this.state.disableZoomOut}
        >
          Header
        </HeaderBar>

        <ToastContainer autoClose={false} newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss={false} draggable={false} />

        <BrowserRouter>
            <Switch>
                <PrivateRoute exact path={BASE_ROUTE} component={LoginPage}/>
                <PrivateRoute exact path={DASHBOARD_ROUTE} component={HomePage}/>
                <PrivateRoute exact path={LOGIN_ROUTE} component={LoginPage}/>
                <PrivateRoute exact path={LOGOUT_ROUTE} component={LogoutPage}/>
                <PrivateRoute path={RACING_ROUTE} component={RacePage}/>
                <PrivateRoute path={SPORTS_ROUTE} component={SportsPage}/>
                <PrivateRoute path={MULTI_ROUTE} component={MultiPage}/>
                <PrivateRoute path={BONUS_ROUTE} component={BonusPage}/>
                <PrivateRoute path={UNIQUE_CLIENTS_ROUTE} component={UniqueClientsPage}/>
                <PrivateRoute path={CHANNEL_SUMMARY_ROUTE} component={ChannelSummaryPage}/>
            </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;

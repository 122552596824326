import React from 'react';
import ReactDOM from 'react-dom';
import env from './Environment';
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.css";
import 'normalize.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import createStore from './store/createStore';

window.env = global.env = App.env = env; // Global everywhere

if (!window.__DH_GLOBALS__) {
	window.__DH_GLOBALS__ = {};
}

const store = createStore();

global.App = {
	store,
};

const render = (Component) => {
	return ReactDOM.render(
		<Provider store={store}>
			<Router>
				<Component />
			</Router>
		</Provider>,
		document.getElementById('root'),
	);
};

render(App);
if (process.env.NODE_ENV !== 'production') {
	if (module.hot && process.env.REACT_APP_HOT) {
		module.hot.accept('./App', () => {
			const NextApp = require('./App').default;
			render(NextApp);
		});
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';

export const toFloat = (num) => {
    return num ? parseFloat(num) : 0;
}

export const commaSeparate = (number) => {
    if(typeof number !== 'string'){
        number = number.toString();
    }

    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
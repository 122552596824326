import React from 'react';

import {data_get} from "../../../utils/data_get";
import {formatCurrency} from '../../../utils/formatCurrency';
import {formatPercentage} from '../../../utils/formatPercentage';

const MultiBreakdown = (props) => {

    const racing_breakdown = data_get(props.breakdown, 'racing', {});
    const sports_breakdown = data_get(props.breakdown, 'sport', {});

    // Bet backs
    let racingBetBacks = data_get(data_get(racing_breakdown, 'bet_types', {}), 'betbacks');
    let sportBetBacks = data_get(data_get(sports_breakdown, 'bet_types', {}), 'betbacks');

    let racingBetBacksTurnover = data_get(racingBetBacks, 'turnover', 0);
    let sportBetBacksTurnover = data_get(sportBetBacks, 'turnover', 0);

    let racingBetBacksResult = data_get(racingBetBacks, 'result', 0);
    let sportBetBacksResult = data_get(sportBetBacks, 'result', 0);

    let betBacksTurnover = racingBetBacksTurnover + sportBetBacksTurnover;
    let betBacksResult = racingBetBacksResult + sportBetBacksResult;

    // Turnover
    let racingTurnover = data_get(racing_breakdown, 'turnover');
    let sportsTurnover = data_get(sports_breakdown, 'turnover');

    // Result
    let racingResults =  data_get(racing_breakdown, 'result');
    let sportsResults =  data_get(sports_breakdown, 'result');

    // Bonus
    let bonus = data_get(racing_breakdown, 'bonus') + data_get(sports_breakdown, 'bonus');
    let bonusResult = data_get(racing_breakdown, 'bonus_result') + data_get(sports_breakdown, 'bonus_result');

    // Totals
    let totalTurnover = racingTurnover + sportsTurnover - betBacksTurnover;
    let totalResults = racingResults + sportsResults - betBacksResult;

	return (
		<div className="p-2 mb-3">
			<table className="table-full-width table-figures table-striped">
				<thead>
					<tr>
						<th className="breakdown-title">Multi Results</th>
						<th className="td-figure">Turnover</th>
						<th className="td-figure">Result</th>
						<th className="td-figure">%</th>
					</tr>
				</thead>
				<tbody>

                    <tr>
                        <td>Racing</td>
                        <td className="td-figure">{formatCurrency(racingTurnover)}</td>
                        <td className="td-figure">{formatCurrency(racingResults)}</td>
                        <td className="td-figure">{formatPercentage(racingTurnover ? racingResults/racingTurnover : 0)}</td>
                    </tr>

                    <tr className="tr-betbacks">
                        <td>Racing BetBacks</td>
                        <td className="td-figure">{formatCurrency(racingBetBacksTurnover)}</td>
                        <td className="td-figure">{formatCurrency( -1 * racingBetBacksResult)}</td>
                        <td className="td-figure">{formatPercentage(racingBetBacksTurnover ? (-1 * racingBetBacksResult)/racingBetBacksTurnover : 0)}</td>
                    </tr>

                    <tr>
                        <td>Sports</td>
                        <td className="td-figure">{formatCurrency(sportsTurnover)}</td>
                        <td className="td-figure">{formatCurrency(sportsResults)}</td>
                        <td className="td-figure">{formatPercentage(sportsTurnover ? sportsResults/sportsTurnover : 0)}</td>
                    </tr>

                    <tr className="tr-betbacks">
                        <td>Sport BetBacks</td>
                        <td className="td-figure">{formatCurrency(sportBetBacksTurnover)}</td>
                        <td className="td-figure">{formatCurrency( -1 * sportBetBacksResult)}</td>
                        <td className="td-figure">{formatPercentage(sportBetBacksTurnover ? (-1 * sportBetBacksResult)/sportBetBacksTurnover : 0)}</td>
                    </tr>

                    <tr className="tr-bonus">
                        <td>Bonus</td>
                        <td className="td-figure">{formatCurrency(bonus)}</td>
                        <td className="td-figure">{formatCurrency(bonusResult)}</td>
                        <td className="td-figure"></td>
                    </tr>

                    <tr className="result-row">
                        <td>Total</td>
                        <td className="td-figure">{ formatCurrency(totalTurnover) }</td>
                        <td className="td-figure">{ formatCurrency(totalResults)}</td>
                        <td className="td-figure">{ formatPercentage( totalTurnover ? totalResults/totalTurnover : 0) }</td>
                    </tr>

				</tbody>
			</table>
		</div>
	);
};

export default MultiBreakdown;

import React from 'react';

export const isEmpty = (obj) => {
    return obj ? Object.keys(obj).length == 0 : true;
}

export const arrayMerge = (array, data) => {
    if((data instanceof Object && Object.keys(data).length > 0) || data){
        array.push(data);
    }

    return array;
}

export const concat = (objects) => {

}
import React from 'react';
import {formatCurrency} from '../../utils/formatCurrency';
import {formatPercentage} from '../../utils/formatPercentage';
import {data_get} from '../../utils/data_get';
import {commaSeparate} from '../../utils/numbers'

const BonusMultiSummary = (props) => {

    let racing = data_get(props.summary, 'racing', {});
    let sport = data_get(props.summary, 'sport', {});

    // Racing
    let racingBonus = data_get(racing, 'bonus');
    let racingBonusCount = data_get(racing, 'bonus_count');
    let racingBonusResult = data_get(racing, 'bonus_result');

    // Sport
    let sportBonus = data_get(sport, 'bonus');
    let sportBonusCount = data_get(sport, 'bonus_count');
    let sportBonusResult = data_get(sport, 'bonus_result');

    // Totals
    let totalBonus = racingBonus + sportBonus;
    let totalBonusCount = racingBonusCount + sportBonusCount;
    let totalBonusResult = racingBonusResult + sportBonusResult;

    return <div className="p-2 mb-3">
        <div className="d-flex justify-content-around mb-2">
            <div className="figure">
                <p className="mb-1">Bonus:</p>
                <h5 className="figure-value-lg">{formatCurrency(totalBonus)}</h5>
            </div>
            <div className="figure">
                <p className="mb-1">Result:</p>
                <h5 className="figure-value-lg">{formatCurrency(totalBonusResult)}</h5>
            </div>
        </div>
        <div className="d-flex justify-content-around mb-2">
            <div className="figure">
                <p className="mb-1">Percentage:</p>
                <h5 className="figure-value-lg">{formatPercentage(totalBonus ? totalBonusResult / totalBonus : 0)}</h5>
            </div>
            <div className="figure">
                <p className="mb-1">Bonus Count:</p>
                <h5 className="figure-value-lg">{totalBonusCount}</h5>
            </div>
        </div>
    </div>;
}

export default BonusMultiSummary;
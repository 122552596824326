import React from 'react';
import {current_year} from "./date";
import {data_get} from "./data_get";

const custom_period_keys = {
    yesterday: 'date',
    last_week: 'week',
};

/**
 * Get current url as string
 */
export const currentUrl = () => {
    let url = new URL(window.location.href);
    return url.toString();
}

/**
 * Get param from current url
 */
export const getUrlParam = (key, defaultValue=null) => {
    let url = new URL(window.location.href);
    let param = url.searchParams.get(key);

    return param ? param : defaultValue;
}

/**
 * Get current route path
 */
export const getCurrentPath = () => {
    let url = new URL(window.location.href);
    return url.pathname;
}

/**
 * Check if current route path is the given path
 */
export const urlIs = (path) => {
    path = !path.startsWith('/') ? '/' + path : path;
    return getCurrentPath() == path;
}

/**
 * Append given param and value to current query string
 */
export const appendUrlParam = (param, value) => {
    let url = new URL(window.location.href);
    url.searchParams.append(param, value);

    return url.toString();
}

/**
 * Remove given query param from current url
 */
export const removeUrlParam = (param) => {
    let url = new URL(window.location.href);
    url.searchParams.delete(param);

    return url.toString();
}

/**
 * Build filter params for stats api calls
 */
export const buildParams = (params) => {

    // fix for yesterday filter
    let filterKey = custom_period_keys[params.filterKey] ?  custom_period_keys[params.filterKey] : params.filterKey;

    let queryParams = {
        filter_key: filterKey ? filterKey : 'year',
        filter_value: params.filterValue ? params.filterValue : current_year(),
    }

    if(params.eventType){
        queryParams['event_type_id'] = params.eventType;
    }

    if(params.meetingType){
        queryParams['meeting_type_id'] = params.meetingType;
    }

    if(params.filterKey == 'range'){
        queryParams['filter_key'] = 'range';
        queryParams['filter_start'] = data_get(params.filterValue,'from_date', null);
        queryParams['filter_end'] = data_get(params.filterValue, 'to_date', null);
    }

    if(params.client && params.client.id){
        queryParams['client_id'] = params.client.id;
    }

    return queryParams;
}

/**
 * Redirect to given path
 */
export const redirectTo = (path) => {
    window.location.href = path;
}

/**
 * Generate api url form current host
 */
export const getApiUrl = () => {
    let url = new URL(window.location.href);

    // url env value for local dev environment
    if(url.host.startsWith('localhost')){
        return process.env.REACT_APP_API_URL;
    }

    // prefix first part of the host name
    let hostParts = url.host.split('.');

    if(hostParts.length){
        hostParts[0] =  'api-' + hostParts[0];
        let apiHost = hostParts.join('.');

        return url.protocol + '//' + apiHost + '/api/'
    }

    return process.env.REACT_APP_API_URL;
}
import React from 'react';

import {data_get} from "../../utils/data_get";
import {formatCurrency} from '../../utils/formatCurrency';
import {formatPercentage} from '../../utils/formatPercentage';

const OverviewBreakdown = (props) => {

    const racing_breakdown = props.results.racing_breakdown ? props.results.racing_breakdown : {};
    const sports_breakdown = props.results.sports_breakdown ? props.results.sports_breakdown : {};
    const multi_breakdown = props.results.multi_breakdown ? props.results.multi_breakdown : {};
    const bonus_breakdown = props.results.bonus_breakdown ? props.results.bonus_breakdown : {};    

    // Singles
    let singleRacingTurnover = data_get(racing_breakdown, 'turnover');
    let singleSportsTurnover = data_get(sports_breakdown, 'turnover');

    let singleRacingResults =  data_get(racing_breakdown, 'result');
    let singleSportsResults =  data_get(sports_breakdown, 'result');

    // Multi
    let multiRacingTurnover = data_get(multi_breakdown.racing, 'turnover');
    let multiRacingResult = data_get(multi_breakdown.racing, 'result');

    let multiSportTurnover = data_get(multi_breakdown.sport, 'turnover');
    let multiSportResult = data_get(multi_breakdown.sport, 'result');

    // Totals
    let racingTotalTurnover = singleRacingTurnover + multiRacingTurnover;
    let sportTotalTurnover = singleSportsTurnover + multiSportTurnover;

    let racingTotalResult = singleRacingResults + multiRacingResult;
    let sportTotalResult = singleSportsResults + multiSportResult;

    // Overall total
    let totalTurnover = racingTotalTurnover + sportTotalTurnover;
    let totalResults = racingTotalResult + sportTotalResult;

	return (
		<div className="p-2 mb-3">
			<table className="table-full-width table-figures table-striped">
				<thead>
					<tr>
						<th className="breakdown-title">Overview Results</th>
						<th className="td-figure">Turnover</th>
						<th className="td-figure">Result</th>
						<th className="td-figure">%</th>
					</tr>
				</thead>
				<tbody>

                    <tr>
                        <td>Racing</td>
                        <td className="td-figure">{formatCurrency(singleRacingTurnover)}</td>
                        <td className="td-figure">{formatCurrency(singleRacingResults)}</td>
                        <td className="td-figure">{formatPercentage(singleRacingTurnover ? singleRacingResults/singleRacingTurnover : 0)}</td>
                    </tr>
                    <tr>
                        <td>Racing Multi</td>
                        <td className="td-figure">{formatCurrency(multiRacingTurnover)}</td>
                        <td className="td-figure">{formatCurrency(multiRacingResult)}</td>
                        <td className="td-figure">{formatPercentage(multiRacingTurnover ? multiRacingResult/multiRacingTurnover : 0)}</td>
                    </tr>
                    <tr className="tr-sub-total">
                        <td>Racing Total</td>
                        <td className="td-figure">{formatCurrency(racingTotalTurnover)}</td>
                        <td className="td-figure">{formatCurrency(racingTotalResult)}</td>
                        <td className="td-figure">{formatPercentage(racingTotalTurnover ? racingTotalResult/racingTotalTurnover : 0)}</td>
                    </tr>
                    <tr>
                        <td>Sport</td>
                        <td className="td-figure">{formatCurrency(singleSportsTurnover)}</td>
                        <td className="td-figure">{formatCurrency(singleSportsResults)}</td>
                        <td className="td-figure">{formatPercentage(singleSportsTurnover ? singleSportsResults/singleSportsTurnover : 0)}</td>
                    </tr>
                    <tr>
                        <td>Sport Multi</td>
                        <td className="td-figure">{formatCurrency(multiSportTurnover)}</td>
                        <td className="td-figure">{formatCurrency(multiSportResult)}</td>
                        <td className="td-figure">{formatPercentage(multiSportTurnover ? multiSportResult/multiSportTurnover : 0)}</td>
                    </tr>
                    <tr className="tr-sub-total">
                        <td>Sport Total</td>
                        <td className="td-figure">{formatCurrency(sportTotalTurnover)}</td>
                        <td className="td-figure">{formatCurrency(sportTotalResult)}</td>
                        <td className="td-figure">{formatPercentage(sportTotalTurnover ? sportTotalResult/sportTotalTurnover : 0)}</td>
                    </tr>

                    <tr className="result-row">
                        <td>Total</td>
                        <td className="td-figure">{ formatCurrency(totalTurnover) }</td>
                        <td className="td-figure">{ formatCurrency(totalResults)}</td>
                        <td className="td-figure">{ formatPercentage( totalTurnover ? totalResults/totalTurnover : 0) }</td>
                    </tr>

				</tbody>
			</table>
		</div>
	);
};

export default OverviewBreakdown;

import React from 'react';
import {data_get} from "./data_get";
import {isEmpty} from './objects';

export const getTurnover = (object) => {
    let turnover = data_get(object, 'turnover');
    let unresulted = data_get(object, 'unresulted');

    return turnover - unresulted;
}

export const normalizeSummary = (object, firstKey) => {
    let turnover = 0;
    let result = 0;

    let concat = [object.bet_types.exotics, object.bet_types.srm];
    let subtract = object.bet_types.betbacks;

    firstKey = object[firstKey];

    if(firstKey){
        firstKey.concat(concat).map((group) => {
            turnover += data_get(group, 'turnover', 0);
            result += data_get(group, 'result', 0);
        });
    }else{
        turnover = data_get(object, 'turnover');
        result = data_get(object, 'result');
    }

    if(subtract){
        turnover = turnover - subtract.turnover;
        result = result - subtract.result;
    }

    object['turnover'] = turnover;
    object['result'] = result;

    return object;
}

export const normalizeBonusSummary = (object, firstKey) => {
    let bonus = 0;
    let count = 0;
    let result = 0;

    let concat = [object.bet_types.exotics, object.bet_types.srm];

    firstKey = object[firstKey];

    if(firstKey){
        firstKey.concat(concat).map((group) => {
            if(group && group.name !== 'BetBacks'){
                bonus += data_get(group, 'bonus', 0);
                count += data_get(group, 'bonus_count', 0);
                result += data_get(group, 'bonus_result', 0);
            }
        });
    }else{
        bonus = data_get(object, 'bonus');
        count = data_get(object, 'bonus_count');
        result = data_get(object, 'bonus_result');
    }

    object['bonus'] = bonus;
    object['count'] = count;
    object['result'] = result;

    return object;
}

export const normalizeBreakdown = (summary, firstKey) => {

    if (isEmpty(summary)) {
        return [];
    }

    let breakDown = summary[firstKey];

    if (breakDown) {
        let bonus = {
            name: 'Bonus',
            turnover: data_get(summary, 'bonus', 0),
            result: data_get(summary, 'bonus_result', 0),
        }

        return breakDown.concat([summary.bet_types.srm, summary.bet_types.exotics, summary.bet_types.betbacks, bonus]);
    }

    return [];
}

export const normalizeBonusBreakdown = (summary, firstKey) => {

    if (isEmpty(summary)) {
        return [];
    }

    let breakDown = summary[firstKey];

    if (breakDown) {
        return breakDown.concat([summary.bet_types.srm, summary.bet_types.exotics]);
    }

    return [];
}
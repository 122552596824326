import {getFileName} from "./strings";

export const download = (response) => {
    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    var fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', getFileName(response.headers['content-disposition']));
    document.body.appendChild(fileLink);

    fileLink.click();
}
import $ from 'jquery';
import "select2";
import { connect } from 'react-redux';
import React, {Component} from "react";
import { setClient, removeClient } from '../../store/entitites/actions/ClientActions'


class ClientSelect extends Component {

    initSelect2 = () => {

      var self = this;

      $(document).ready(function() {
        // Init select 2
        let clientSelect = $('.client-select').select2({
          placeholder: 'Search Clients',
          width: '25%',
          allowClear: true,
          dataType: 'json',
          minimumInputLength: 2,
          ajax: {
            url: 'resources/clients',
            data: function (params) {
              return {
                search: params.term,
              }
            },
            processResults: function (data) {
              // Transform to Select2 data format.
              var results = data.data.map((client) => {
                return {
                  id: client.id,
                  text: client.username
                }
              })

              return {
                results: results
              };
            },
          }
        }).on('select2:select', function (e) {
          var client = JSON.stringify(e.params.data);
          localStorage.setItem('client', client);
          self.props.setClient(e.params.data);
        }).on('select2:clear', function (e) {
          localStorage.removeItem('client');
          self.props.removeClient();
        }).on("select2:unselecting", function(e) {
          $(this).data('state', 'unselected');
        }).on("select2:open", function(e) {
          if ($(this).data('state') === 'unselected') {
              $(this).removeData('state');
              $(this).select2('close');
          }
        });

        // Preselect client if exists.
        let data = self.props.client;

        if(data) {
          var option = new Option(data.text, data.id, true, true);
          clientSelect.append(option).trigger('change');
        }

      });
    }

    componentDidMount () {
      var client = JSON.parse(localStorage.getItem('client'));
      this.props.setClient(client);
      this.initSelect2();
    }

    render () {
      return (
        <select className="client-select"></select>
      );
    }
}

function mapStateToProps (state) {
  return {
    client: state.entities.client
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setClient: (data) => dispatch(setClient(data)),
    removeClient: () => dispatch(removeClient()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelect);
import React from 'react';
import moment from 'moment';

export const yesterday_date = () => {
    return moment().subtract(1, 'day').format('YYYY-MM-DD');
}

export const current_date = () => {
    return moment().format('YYYY-MM-DD');
}

export const current_month_with_year = () => {
    return moment().format('YYYY-MM');
}

export const current_week_with_year = () => {
    let today = moment();
    let week = today.format('WW');

    // Check for first week.
    week = (today.month() == 0 && week > 10) ? '00' : week;

    return today.format('YYYY') + '-' + week;
}

export const last_week = () => {
    let today = moment();
    let lastWeekStart = today.subtract(1, 'weeks');
    let week = lastWeekStart.format('WW');

    // Check for first week.
    week = (lastWeekStart.month() == 0 && week > 10) ? '00' : week;

    return lastWeekStart.format('YYYY') + '-' + week;
}

export const current_year = () => {
    return moment().format('YYYY');
}
import React, {useState,useEffect} from 'react';
import {uniqueId} from "underscore";
import { useSelector } from 'react-redux';

import {Button} from "react-bootstrap";
import Loading from "../../components/Elements/Loading";

import {fetchTopClients} from "../../store/entitites/actions/ClientActions";

import {formatCurrency} from '../../utils/formatCurrency';

import {formatPercentage} from '../../utils/formatPercentage';

import {commaSeparate} from '../../utils/numbers'

import ReactTooltip from 'react-tooltip';

let eventTypes = {
    racing: 1,
    sports: 2,
    multi: 3,
}

let meetingTypes = {
    gallops: 1,
    greyhounds: 2,
    harness: 3,
}

const TopClient = (props) => {

    let {periodKey, periodValue, refresh, onClientLimitChange, onClientTypeChange, source, isBonus} = props;
    const client = useSelector(state => state.entities.client);

    const [topClients, setTopClients] = useState([]);
    const [clientType, setClientType] = useState(props.type);
    const [eventType, setEventType] = useState(eventTypes[props.eventType] ? eventTypes[props.eventType] : null);
    const [meetingType, setMeetingType] = useState(meetingTypes[props.meetingType] ? meetingTypes[props.meetingType] : null);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(props.clientLimit);

    useEffect(() => {
        setTopClients(props.topClients);
    }, [props.topClients])

    const handleLimitChange = (e) => {
        let selectedLimit = e.target.value;

        setLimit(selectedLimit);
        onClientLimitChange(selectedLimit);

        if(refresh) {
            // Check and cancel previous pending requests
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
            return;
        }

        setLoading(true);

        fetchTopClients(eventType, clientType, selectedLimit, periodKey, periodValue, meetingType, source, (props.isBonus === true)).then((clients) => {
            setLoading(false);
            setTopClients(clients);
        });
    }

    const handleClientTypeChange = (e) => {
        let type = e.target.value;

        setClientType(type);
        onClientTypeChange(type);

        if(refresh) {
            // Check and cancel previous pending requests
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
            return;
        }

        setLoading(true);

        fetchTopClients(eventType, type, limit, periodKey, periodValue, meetingType, source, (props.isBonus === true)).then((clients) => {
            setLoading(false);
            setTopClients(clients);
        });
    }

    return (
        <div>
            { !(client && client.id) &&
                <div className="p-2 mb-3">
                    <div className="d-flex justify-content-between">
                        <p className="font-weight-bold">Top Clients</p>
                        <div className="d-flex justify-content-between align-items-start">
                            <select className="form-control select-xs ml-2" defaultValue={clientType} onChange={handleClientTypeChange}>
                                <option value="winners">Winners</option>
                                <option value="losers">Losers</option>
                                <option value="top_turnover">Top Turnover</option>
                            </select>

                            <select className="form-control select-xs ml-2" onChange={handleLimitChange}>
                                <option value="10">Top 10</option>
                                <option value="20">Top 20</option>
                                <option value="50">Top 50</option>
                                <option value="100">Top 100</option>
                            </select>
                        </div>
                    </div>

                    <div className="clients-table-wrapper" style={{position:'relative'}}>
                        <Loading isLoading={loading}/>
                        <table className="table-full-width table-figures" style={{ marginTop: !isBonus ? '2rem' : '0' }}>
                        { (!isBonus && topClients && topClients.length > 0) &&
                            <thead>
                                <tr>
                                    <th className="breakdown-title"></th>
                                    <th className="td-figure-center">No.&nbsp;Bets</th>
                                    <th className="td-figure-center">T/O</th>
                                    <th className="td-figure-center">Result</th>
                                    <th className="td-figure-center">%</th>
                                </tr>
                            </thead>
                        }      
                            <tbody>
                            {topClients && topClients.length > 0 ? topClients.map((client) => {
                                return <tr key={uniqueId()}>
                                        <td>
                                            { client.risk_client_url ? <a key={uniqueId()} href={client.risk_client_url} target="_blank">{client.username}</a> : client.username }
                                            <span className="client-tag-list">
                                                {client.tags.length > 0 && client.tags.map(tag => {
                                                    return <span key={uniqueId()}>
                                                        <span className="client-tag" data-tip={tag.name} data-for={client.username} style={{'--tag-color': tag.color}}></span>
                                                        <ReactTooltip id={client.username}>{}</ReactTooltip>
                                                    </span>
                                                })}
                                            </span>
                                        </td>
                                        { ( !isBonus ) ?
                                            <>
                                                <td className="td-figure-center">{commaSeparate(client.amounts.bet_count)}</td>
                                                <td className="td-figure-center">{formatCurrency(client.amounts.turnover, true)}</td>
                                                {(parseFloat(client.amounts.result) > 0) &&
                                                   <td className="td-figure-center"><div className="td-figure-red">{formatCurrency(client.amounts.result, true)}</div></td>
                                                }
                                                {(parseFloat(client.amounts.result) < 0) &&
                                                   <td className="td-figure-center"><div className="td-figure-green">{formatCurrency(client.amounts.result, true)}</div></td>
                                                }
                                                {(parseFloat(client.amounts.result) == 0) &&
                                                   <td className="td-figure-center">{formatCurrency(client.amounts.result, true)}</td>
                                                }
                                                <td className="td-figure-center">{formatPercentage(client.amounts.percentage, true)}</td>
                                            </>
                                            :
                                            <td className="text-right">{formatCurrency(client.amount)}</td>
                                        }
                                    </tr>
                            }) : <tr><td colSpan="2" className="text-center py-2">No clients found</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            
        </div>
    );
}

export default TopClient;
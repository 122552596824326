// Libraries
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { cancellableToken } from '../common/ajax';
import FiltersButton from "../components/Common/FiltersButton";
import Loading from "../components/Elements/Loading";
import uniqid from 'uniqid';

import { Doughnut } from 'react-chartjs-2';

// Actions
import {getSourceSummary} from '../store/entitites/actions/ChannelSummaryActions';

// Components
import PeriodFilter from '../components/Filters/PeriodFilter';
import ScoreAlert from "../components/Common/ScoreAlert";

// Utils
import { current_date } from '../utils/date';
import {formatNumber} from '../utils/formatNumber';
import { formatPercentage } from '../utils/formatPercentage';
import { formatCurrency } from '../utils/formatCurrency';

let source;
let timeout;

const ChannelSummaryPage = (props) => {

    if( ! localStorage.getItem('authHeader')){
        window.location = '/login';
    }

    let defaultChartColors = [
        '#8865FF',
        '#FF6FA5',
        '#FB9EC4',
        '#FFDAC0',
        '#FFC7BA',
    ];

    let chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
             tooltip: {
                displayColors: false,
                bodyFont: {
                        size: 9
                },
                callbacks: {
                    label: function(context) {
                        return context.label + ': ' + (context.parsed * 100);
                    }
                }
            }
        },
    };

    let currencyChartOptions = {...chartOptions, ...{
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                bodyFont: {
                        size: 9
                },
                callbacks: {
                    label: function(context) {
                        return context.label + ': ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
                    }
                }
            }
        }
    }};

    /** State * */

    // Loading states
	const [isLoading, setIsLoading] = useState(false);

    //- data
    const [summary, setSummary] = useState([]);

    const [clientChartData, setClientChartData] = useState({});
    const [unresultedChartData, setUnresultedChartData] = useState({});
    const [stakeChartData, setStakeChartData] = useState({});
    const [ggrChartData, setGGRChartData] = useState({});

    //-- filters
    const [periodFilterKey, setPeriodFilterKey] = useState('date');
    const [periodFilterValue, setPeriodFilterValue] = useState(current_date());
    const [showFilters, setShowFilters] = useState(false);
    const client = useSelector(state => state.entities.client);

	useEffect(() => {
        clearTimeout(timeout);
        let fetchedData = true;

        if(! fetchedData){
            return null;
        }

        loadPageData(periodFilterKey, periodFilterValue, client);

        return () => (fetchedData = false);
    }, [periodFilterKey, periodFilterValue, client]);

    useEffect(() => {
        loadPageData(periodFilterKey, periodFilterValue, client);
    }, []);

    /**
     * Fetch all page data
     *
     * @param {string} period
     * @param {string} value
     */
    const loadPageData = (period, value, client) => {
        setIsLoading(true);

        // Check and cancel previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel('request');
        }

        source = cancellableToken.source();

        // Fetch sources summary
        getSourceSummary(periodFilterKey, periodFilterValue, source, client).then((sourcesSummary) => {

            if(!sourcesSummary){
                return;
            }

            setIsLoading(false);

            setSummary(sourcesSummary);

            setClientChartData(createChartData(sourcesSummary, 'unique_clients'));
            setUnresultedChartData(createChartData(sourcesSummary, 'unresulted'));
            setStakeChartData(createChartData(sourcesSummary, 'turnover'));
            setGGRChartData(createChartData(sourcesSummary, 'result'));
        });
    }

    /**
     * Handle period change
     *
     * @param string period
     * @param string value
     */
    const onPeriodChange = (period, value) => {
        setIsLoading(true);
        setPeriodFilterKey(period);
        setPeriodFilterValue(value);
    }

    /**
     * Toggle display filters
     */
     const onFiltersClick = () => {
        setShowFilters( ! showFilters);
    }

    const createChartData = (sourcesSummary, field) => {
        if (!sourcesSummary) {
            return {};
        }

        let labels = [];
        let data = [];
        let colors = [];

        sourcesSummary.map((single, index) => {
            labels.push(single.label.replace('_', ' '));
            data.push(single[field]/100);
            colors.push(single.color ? single.color : (defaultChartColors[index] ? defaultChartColors[index] : null));
        });

        return {
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: colors,
                cutout: '70%',
            }],
        };
    }

	return (
		<Container className="score-container-xl py-4">

			{/* Filters */}
			<div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-sm-row flex-md-row">
				<PeriodFilter showFilters={showFilters} onChange={onPeriodChange} isLoading={isLoading}/>

                <div className="col px-0 d-flex justify-content-between justify-content-md-end justify-content-lg-end align-items-center flex-1">
                    <FiltersButton onClick={onFiltersClick}/>
                </div>
			</div>

            <Loading isLoading={isLoading}/>

             {/* Message  */}
             
             {summary.message &&
                <ScoreAlert show="true">
                    <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{width: '16px', marginRight: '5px'}}><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    {summary.message}
                </ScoreAlert>
            }

			{/* Score cards */}
            <div className="row justify-content-start align-items-start position-relative mx-0">
                <div className="col-8 score-card product-score-card">

                    <div className="row pt-5 pb-4" style={{justifyContent: 'space-evenly'}}>
                        <div className="channel-chart-wrapper">
                            <span className="chart-title">Unique clients</span>
                            <Doughnut data={clientChartData} options={chartOptions}/>
                        </div>
                        <div className="channel-chart-wrapper">
                            <span className="chart-title">Unresulted</span>
                            <Doughnut data={unresultedChartData} options={currencyChartOptions}/>
                        </div>
                        <div className="channel-chart-wrapper">
                            <span className="chart-title">Stake</span>
                            <Doughnut data={stakeChartData} options={currencyChartOptions}/>
                        </div>
                        <div className="channel-chart-wrapper">
                            <span className="chart-title">GGR</span>
                            <Doughnut data={ggrChartData} options={currencyChartOptions}/>
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-12 pr-5 py-5">
                            <table className="table-full-width table-figures table-striped">
                                <thead>
                                    <tr>
                                        <th>Channel</th>
                                        <th className="text-right">Unique clients</th>
                                        <th className="text-right">Unresulted</th>
                                        <th className="text-right">No. of Bets</th>
                                        <th className="text-right">Stake</th>
                                        <th className="text-right">GGR</th>
                                        <th className="text-right">Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {summary && summary.map((single, index) => {
                                        return <tr key={uniqid()}>
                                                    <td>
                                                        <span className="chart-color-legend" style={{backgroundColor: single.color ? single.color : defaultChartColors[index]}}></span>
                                                        {single.label}
                                                    </td>
                                                    <td className="text-right">{single.unique_clients}</td>
                                                    <td className="text-right">{formatCurrency(single.unresulted)}</td>
                                                    <td className="text-right">{single.bet_count}</td>
                                                    <td className="text-right">{formatCurrency(single.turnover)}</td>
                                                    <td className="text-right">{formatCurrency(single.result)}</td>
                                                    <td className="text-right">{formatPercentage(single.percentage)}</td>
                                                </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>

                        
                    </div>
                </div>
			</div>
		</Container>
	);
};

export default ChannelSummaryPage;

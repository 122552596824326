import React from 'react';
import {formatCurrency} from '../../utils/formatCurrency';
import {formatPercentage} from '../../utils/formatPercentage';
import {data_get} from '../../utils/data_get';
import {commaSeparate} from '../../utils/numbers'

const BonusSummary = (props) => {

    let bonus  = data_get(props.summary, 'bonus');
    let bonusResult  = data_get(props.summary, 'bonus_result');
    let bonusCount  = data_get(props.summary, 'bonus_count');

    let unresulted = data_get(props.summary, 'unresulted', 0);

    return <div className="p-3 mb-3">
            <div className="d-flex justify-content-around mb-2">
                <div className="figure">
                    <p className="mb-1">Bonus:</p>
                    <h5 className="figure-value-lg">{formatCurrency(bonus)}</h5>
                </div>
                <div className="figure">
                    <p className="mb-1">Result:</p>
                    <h5 className="figure-value-lg">{formatCurrency(bonusResult)}</h5>
                </div>
            </div>
            <div className="d-flex justify-content-around mb-2">
                <div className="figure">
                    <p className="mb-1">Percentage:</p>
                    <h5 className="figure-value-lg">{formatPercentage(bonus ? bonusResult/bonus : 0)}</h5>
                </div>
                <div className="figure">
                    <p className="mb-1">Bonus Count:</p>
                    <h5 className="figure-value-lg">{bonusCount}</h5>
                </div>
            </div>
        </div>;
}

export  default BonusSummary;
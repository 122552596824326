import React from 'react';

import {data_get} from "../../../utils/data_get";
import {formatCurrency} from '../../../utils/formatCurrency';
import {formatPercentage} from '../../../utils/formatPercentage';

const MultiBreakdown = (props) => {

    const racing_breakdown = data_get(props.breakdown, 'racing', {});
    const sports_breakdown = data_get(props.breakdown, 'sport', {});

    // Bonus
    let racingBonus = data_get(racing_breakdown, 'bonus');
    let sportsBonus = data_get(sports_breakdown, 'bonus');

    // Result
    let racingBonusResult =  data_get(racing_breakdown, 'bonus_result');
    let sportsBonusResults =  data_get(sports_breakdown, 'bonus_result');

    // Totals
    let totalBonus = racingBonus + sportsBonus;
    let totalBonusCount = racingBonus + sportsBonus;
    let totalBonusResults = racingBonusResult + sportsBonusResults;

	return (
		<div className="p-2 mb-3">
			<table className="table-full-width table-figures table-striped">
				<thead>
					<tr>
						<th className="breakdown-title">Multi Results</th>
						<th className="td-figure">Bonus</th>
						<th className="td-figure">Result</th>
						<th className="td-figure">%</th>
					</tr>
				</thead>
				<tbody>

                    <tr>
                        <td>Racing</td>
                        <td className="td-figure">{formatCurrency(racingBonus)}</td>
                        <td className="td-figure">{formatCurrency(racingBonusResult)}</td>
                        <td className="td-figure">{formatPercentage(racingBonus ? racingBonusResult/racingBonus : 0)}</td>
                    </tr>

                    <tr>
                        <td>Sports</td>
                        <td className="td-figure">{formatCurrency(sportsBonus)}</td>
                        <td className="td-figure">{formatCurrency(sportsBonusResults)}</td>
                        <td className="td-figure">{formatPercentage(sportsBonus ? sportsBonusResults/sportsBonus : 0)}</td>
                    </tr>

                    <tr className="result-row">
                        <td>Total</td>
                        <td className="td-figure">{ formatCurrency(totalBonus) }</td>
                        <td className="td-figure">{ formatCurrency(totalBonusResults)}</td>
                        <td className="td-figure">{ formatPercentage( totalBonus ? totalBonusResults/totalBonus : 0) }</td>
                    </tr>

				</tbody>
			</table>
		</div>
	);
};

export default MultiBreakdown;

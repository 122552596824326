import React from 'react';
import RacingSummary from "../Racing/RacingSummary";
import TopClient from '../TopClients';

const SportScoreCard = (props) => {

    let {periodKey, periodValue, refresh, onClientLimitChange, onClientTypeChange, clientType, clientLimit, source} = props;

    return (
            <div className="score-card product-score-card sport-score-card">
                <RacingSummary title={props.title} breakdown={props.breakdown} summary={props.summary}/>
                <TopClient  topClients={props.topClients}
                            type={clientType}
                            limit={clientLimit}
                            source={source}
                            refresh={refresh}
                            eventType="sports"
                            periodKey={periodKey}
                            periodValue={periodValue}
                            onClientTypeChange={onClientTypeChange}
                            onClientLimitChange={onClientLimitChange} />
            </div>
    );
}

export default SportScoreCard;
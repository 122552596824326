// Libraries
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Container } from 'react-bootstrap';

// Constants
import {DASHBOARD_ROUTE}  from '../constants/RouteConstants';

// Actions
import {login, checkAuth, setTokenHeader} from '../store/application/applicationActions';

// Components
import LoginLoader from '../components/Login/LoginLoader';

// Utils
import {getUrlParam}  from '../utils/url';


const LoginPage = (props) => {

    let { loggedIn, login, checkAuth } = props;

    const authToken = getUrlParam('auth_token');

    /** State **/
    const [hasValidToken, setHasValidToken] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loaderLoading, setLoaderLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [loggedin, setLoggedIn] = useState(false);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    /**
     * useEffect hook
     */
    useEffect(() => {
        if(localStorage.getItem('authHeader')){
            window.location = DASHBOARD_ROUTE;
        }

        // If there is a token present, check authentication with that token
        if(authToken){
            setLoaderLoading(true);
            checkAuth(authToken).then(response => {                
                if(response.status == 200){
                    setTokenHeader('Bearer', authToken);

                    window.location = DASHBOARD_ROUTE;
                    return;
                }

                setLoginError('Something went wrong when authenticating your credentials. Please try again.');
                setLoaderLoading(false);

            }).catch(error => { 
                setLoaderLoading(false);
                setHasValidToken(false);
                setLoginError('You tried to login with an invalid token. Please sign in with your credentials.');
            });
        }

    }, []);

    /**
     * Handle username change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    /**
     * Handle password change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }


    /**
     * Handle login for submition
     *
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    const onLoginFormSubmit = (e) => {
        e.preventDefault();

        setLoginError('');

        if( !username || !password){
            setLoginError('Please enter username and password');
            return;
        }

        setLoading(true);
        login(username, password).then(response => {
            setLoading(false);

            if(response.status == 200){

                let authToken = response.data.data.plainTextToken;
                setTokenHeader('Bearer', authToken);
                
                window.location = DASHBOARD_ROUTE;
                return;
            }

            setLoginError('Something went wrong when authenticating your credentials. Please try again.');

        }).catch(error => {
            setLoading(false);
            error = error.response;

            if(error && error.status == 401){
                setLoginError('Username or password is invalid');
            }else{
                setLoginError('Something went wrong when authenticating your credentials. Please try again.');
            }
        });
    }

	return (
		<Container className="score-container-xl login-container py-4">
			<div className="col-lg-4 mx-auto">
                <div className="card login-card">
                    <div className="card-header bg-white border-0 pb-0 pt-4">
                        <h4 className="text-center font-weight-bold mb-0">Sign in to Scoreboard</h4>
                    </div>
                    <div className="card-body">
                            <p className="text-danger text-center fade-in">{!loading && loginError ? loginError : ''}</p>

                            {loaderLoading && !hasValidToken ? <LoginLoader/> : ''}

                            {!loaderLoading && !hasValidToken ? 
                                <form className="form fade-in" onSubmit={onLoginFormSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="">Username</label>
                                        <input type="text" className="form-control" onChange={handleUsernameChange} autoComplete="on"/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="">Password</label>
                                        <input type="password" className="form-control" onChange={handlePasswordChange}/>
                                    </div>

                                    <div className="form-group text-center mt-4">
                                        <button className="btn btn-primary btn-block">
                                            {loading ? 'Signing in...' : 'Sign in'}
                                        </button>
                                    </div>
                                </form>
                            : null}


                        {/* {loggedIn == null && authToken && !invalidToken ? <LoginLoader/> : ( !authToken || invalidToken || loggedIn.status != 200 ? <LoginForm onSubmit={submitLoginForm} invalidToken={invalidToken} loginResponse={loggedIn}/> : <LoginLoader message="You have logged in"/>)} */}
                    </div>
                </div>
            </div>
		</Container>
	);
};


const mapStateToProps = (state) => {
	return {
        loggedIn: state.application.auth
    }
};

export default connect(mapStateToProps, {login, checkAuth})(LoginPage);
// Libraries
import React, {useEffect} from 'react';

// Actions
import {logout} from '../store/application/applicationActions';

const LogoutPage = () => {

    useEffect(() => {
        logout();

        window.location = '/login';
    }, []);

	return null;
};

export default LogoutPage;
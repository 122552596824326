import $ from 'jquery';
import axios from 'axios';
import env from '../Environment';
import {
	logout,
	setTokenHeader,
	buildTokenHeader,
	// fetchAuthenticatedUser,
} from '../store/application/applicationActions';

// laravel_token is being set in the cookie and is remember after page load but it doesn't seem to authenticate the user
// this might be because we aren't using sessions and so the cookie becomes meaningless after it is first created
if (localStorage.getItem('authHeader')) {
	axios.defaults.headers.common['Authorization'] = localStorage.getItem('authHeader');
}

$.ajaxSetup({
	contentType: 'application/json',
	headers: {
		'Authorization': localStorage.getItem('authHeader'),
		'X-Requested-With': 'XMLHttpRequest',
	},
	beforeSend: function(xhr, options) {
        options.url = env('apiUrl', 'https://localhost:8000/api') + options.url;
    }
});

axios.defaults.withCredentials = false;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = env('apiUrl', 'https://localhost:8000');

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		/**
		 * On specific 401 Errors, attempt to fresh the user token
		 */
		if (error && error.response && error.response.status === 401 && error.response.data === 'Unauthorized.') {
			return (
				post('/auth/refresh')
					// If we can't refresh then log the user out
					.catch((err) => global.App.store.dispatch(logout()))
					.then((response) => {
						if (!response || response.errors) {
							return global.App.store.dispatch(logout());
						}

						const refresh = response.data.data;

						// Update the header token, and resync the user
						setTokenHeader(refresh.token_type, refresh.access_token);
						// global.App.store.dispatch(fetchAuthenticatedUser());

						// Update the header for the previous call
						error.config.headers['Authorization'] = buildTokenHeader(refresh.token_type, refresh.access_token);

						// Redo the previous call
						return axios.request(error.config);
					})
			);
		}

		return Promise.reject(error);
	},
);

/**
 * Ajax methods to match apache verbs
 */
export const post = axios.post;
export const get = axios.get;
export const put = axios.put;
export const patch = axios.patch;
export const remove = axios.delete;
export const wasCancelled = axios.isCancel;
export const cancellableToken = axios.CancelToken;

export default axios;

import {get} from '../../../common/ajax';
import {buildParams} from "../../../utils/url";
import {redirectIfUnauthenticated} from "../../application/applicationActions";
import {toast} from "react-toastify";

export const getRacingReport = (periodKey, periodValue, type, limit) => {

    let params = buildParams({filterKey: periodKey, filterValue: periodValue});
    params['type'] = type;
    params['limit'] = limit;

	return get('/stats/reports/summary/racing', { params })
        .then((response) => {
            return response;
        })
        .catch((e) => {
            redirectIfUnauthenticated(e.response.status);
            toast.error('Error fetching racing report', {toastId: 'racing-toast'});
    });

};
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "./reducers";

const OPTIONS = {
  name: "Scoreboard",
  actionCreators: {}
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(OPTIONS)
  : compose;

export default preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(ReduxThunk) //, middleware)
    )
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot && process.env.REACT_APP_HOT) {
      module.hot.accept("./reducers", () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

import React from "react";
import { PlusIcon, DashIcon } from '@primer/octicons-react'
import { Navbar,Container,Row,Dropdown } from "react-bootstrap";
import ClientSelect from '../Common/ClientSelect';
import {
	LOGIN_ROUTE,
	LOGOUT_ROUTE,
	BASE_ROUTE,
	RACING_ROUTE,
	SPORTS_ROUTE,
	DASHBOARD_ROUTE,
	MULTI_ROUTE,
	UNIQUE_CLIENTS_ROUTE,
	BONUS_ROUTE,
	CHANNEL_SUMMARY_ROUTE
} from "../../constants/RouteConstants";
import { urlIs } from "../../utils/url";

const HeaderBar = (props) => {

  	return (
		<>
			{ !urlIs(LOGIN_ROUTE) ?
				<Navbar variant="top" className="d-flex flex-wrap justify-content-between">
					<div>
						<img src="/images/brand-logo.png" width="30" height="30" className="d-inline-block align-top mr-2" alt="Scoreboard"/>
						<Navbar.Brand href="/">Scoreboard</Navbar.Brand>
					</div>
					{ localStorage.getItem('authHeader') ?
					<div className="flex-grow-1 text-left text-sm-right mt-2 mt-sm-0">
						{!urlIs(CHANNEL_SUMMARY_ROUTE) ? <ClientSelect /> : null} 
						<button onClick={props.onClickZoomOut} className={ props.disableZoomOut ? 'btn btn-sm btn-square gray-200 mx-2 btn-disabled' : 'btn btn-sm btn-square gray-200 mx-2' } disabled={props.disableZoomOut}>
							<DashIcon aria-label="Zoom out" verticalAlign='middle' size={12} />
						</button>
						<button className="btn btn-light btn-sm gray-200 mx-2" onClick={props.onClickReset}>Reset</button>
						<button onClick={props.onClickZoomIn} className={ props.disableZoomIn ? 'btn btn-sm btn-square gray-200 mx-2 btn-disabled' : 'btn btn-sm btn-square gray-200 mx-2' } disabled={props.disableZoomIn}>
							<PlusIcon aria-label="Zoom in" verticalAlign='middle' size={12} />
						</button>
						<a href={LOGOUT_ROUTE} className="btn btn-primary btn-sm ml-2">Logout</a>
					</div>
					:''}
				</Navbar>
				: null}

				{ localStorage.getItem('authHeader') ?
				<Navbar className="action-bar px-5">
					<Container className="score-container-xl">
						<Row>
							<a href={BASE_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(DASHBOARD_ROUTE) ? 'btn-active' : '')}>Overview</a>
							<a href={RACING_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(RACING_ROUTE) ? 'btn-active' : '')}>Racing</a>
							<a href={SPORTS_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(SPORTS_ROUTE) ? 'btn-active' : '')}>Sport</a>
							<a href={MULTI_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(MULTI_ROUTE) ? 'btn-active' : '')}>Multi</a>
							<a href={BONUS_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(BONUS_ROUTE) ? 'btn-active' : '')}>Bonus</a>
							<a href={UNIQUE_CLIENTS_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(UNIQUE_CLIENTS_ROUTE) ? 'btn-active' : '')}>Unique Clients</a>
							<a href={CHANNEL_SUMMARY_ROUTE} varient="primary" size="sm" className={'mr-2 btn btn-primary ' + (urlIs(CHANNEL_SUMMARY_ROUTE) ? 'btn-active' : '')}>Channel Summary</a>
						</Row>
					</Container>
				</Navbar>
				: ''}
		</>
  	);
};

export default HeaderBar;

import React, {useState, forwardRef} from 'react';
import {Button} from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import DateRange from "../Common/DateRange";
import { yesterday_date, current_date, current_month_with_year, current_week_with_year, last_week, current_year } from '../../utils/date';
import DatePicker from "react-datepicker";
import moment from 'moment';
import {ChevronDownIcon} from '@primer/octicons-react';

const PeriodFilter = (props) => {
    const [period, setPeriod] = useState('date');

    const [customMonth, setCustomMonth] = useState(null);
    const [monthlyButtonText, setMonthlyButtonText] = useState('Monthly');

    const [customYear, setCustomYear] = useState(null);
    const [yearlyButtonText, setYearlyButtonText] = useState('Yearly');

    const handlePeriodChange = (selectedPeriod, value) => {
        setPeriod(selectedPeriod);
        setCustomMonth(null);
        setCustomYear(null);
        setMonthlyButtonText('Monthly');
        setYearlyButtonText('Yearly');

        if(props.onChange){
            props.onChange(selectedPeriod, value);
        }
    }

    const getPeriodValue = (period) => {
        switch(period){
            case 'date':
                return current_date();
            case 'yesterday':
                return yesterday_date();
            case 'week':
                return current_week_with_year();
            case 'last_week':
                return last_week();
            case 'month':
                    return current_month_with_year();
            case 'year':
                return current_year();
            default:
                return current_date();
        }
    }

    const handleMonthChange = (month) => {
        setPeriod('month');
        setCustomMonth(month);
        setCustomYear(null);

        setYearlyButtonText('Yearly');

        month = moment(month);

        setMonthlyButtonText(month.format('MMM YYYY'));

        if(props.onChange){
            props.onChange('month', month.format('YYYY-MM'));
        }
    }

    const handleYearChange = (year) => {
        setPeriod('year');
        setCustomMonth(null);
        setCustomYear(year);

        setMonthlyButtonText('Monthly');

        year = moment(year);

        setYearlyButtonText(year.format('YYYY'));

        if(props.onChange){
            props.onChange('year', year.format('YYYY'));
        }
    }

    // Component
    const ButtonGroupAddon = forwardRef(({onClick}, ref) => {
        return  <Button size="sm" variant="secondary-line" ref={ref} className="btn-addon" onClick={onClick}>
                    <ChevronDownIcon size="small" className="size-13" />
                </Button>
    })


	return (
		<div className={'d-none d-sm-flex d-md-flex d-lg-flex justify-content-between align-items-start flex-column align-items-lg-center flex-lg-row period-filters ' + (props.showFilters ? 'show' : '')} >
            <div className={'overlay ' + (!props.isLoading ? 'hidden' : '')}></div>
			<div className="mb-2 mb-lg-0 filter-buttons">
				<div className="period-button-wrapper">
                    <Button size="sm" variant="secondary-line"  className={ 'mr-2 ' + (period === 'date' ? 'active' : '')} onClick={()=>{handlePeriodChange('date', getPeriodValue('date'))}}>
                        Daily
                    </Button>
                </div>
                <div className="period-button-wrapper">
                    <Button size="sm" variant="secondary-line"  className={ 'mr-2 ' + (period === 'yesterday' ? 'active' : '')} onClick={()=>{handlePeriodChange('yesterday', getPeriodValue('yesterday'))}}>
                        Yesterday
                    </Button>
                </div>
				<div className="period-button-wrapper">
                    <Button size="sm" variant="secondary-line"  className={ 'mr-2 ' + (period === 'week' ? 'active' : '')} onClick={()=>{handlePeriodChange('week', getPeriodValue('week'))}}>
                        Weekly
                    </Button>
                </div>
                <div className="period-button-wrapper">
                    <Button size="sm" variant="secondary-line"  className={ 'mr-2 ' + (period === 'last_week' ? 'active' : '')} onClick={()=>{handlePeriodChange('last_week', getPeriodValue('last_week'))}}>
                        Last Week
                    </Button>
                </div>
                <div className="period-button-wrapper">
                    <div className="period-button-group mr-2">
                        <Button size="sm" variant="secondary-line"  className={ (period === 'month' ? 'active' : '')} onClick={()=>{handlePeriodChange('month', getPeriodValue('month'))}}>
                            {monthlyButtonText}
                        </Button>

                        <DatePicker customInput={<ButtonGroupAddon/>} dateFormat="MMM yyyy" selected={customMonth} onChange={handleMonthChange} placeholderText="Month picker" showMonthYearPicker showFullMonthYearPicker showTwoColumnMonthYearPicker/>
                    </div>
                </div>
				<div className="period-button-wrapper">
                    <div className="period-button-group mr-2">
                        <Button size="sm" variant="secondary-line"  className={ (period === 'year' ? 'active' : '')} onClick={()=>{handlePeriodChange('year', getPeriodValue('year'))}}>
                            {yearlyButtonText}
                        </Button>

                        <DatePicker customInput={<ButtonGroupAddon/>} dateFormat="yyyy" selected={customYear} onChange={handleYearChange} placeholderText="Year picker" showYearPicker/>
                    </div>
                </div>
			</div>

			<DateRange handleChange={handlePeriodChange} clearDates={period !== 'range'}/>
		</div>
	);
};

export default PeriodFilter;
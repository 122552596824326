import React from "react";

const RefreshToggleButton = (props) => {
    return (
        <div className="custom-control custom-switch custom-switch-xl ml-2" data-toggle="tooltip" title="Auto Refresh">
            <input type="checkbox" id="autoRefresh" className="custom-control-input" onChange={props.onChange} checked={props.checked} />
            <label className="custom-control-label" htmlFor="autoRefresh"></label>
        </div>
    )
}

export default RefreshToggleButton;
import React from 'react';
import {formatCurrency} from '../../../utils/formatCurrency';
import uniqueid from 'uniqid';
import {data_get} from '../../../utils/data_get';
import {formatPercentage} from '../../../utils/formatPercentage';
import CollapseIndicator from "../../Elements/CollapseIndicator";
import {commaSeparate} from '../../../utils/numbers'

const SportBreakdown = (props) => {

    const handleCollapseTriggerClick = (e) => {
        let targetClass = e.currentTarget.getAttribute('collapse-target');
        let collapseNodes = document.querySelectorAll('.' + targetClass);

        if(collapseNodes){
            collapseNodes.forEach((node) => {
                node.className.indexOf('hidden') > -1 ? node.classList.remove('hidden') : node.classList.add('hidden');
            });
        }
    }

    return (
            <div className="score-card sports-breakdown ml-2 mt-1">
                <table className="table table-striped sports-breakdown-table">
                    <thead>
                        <tr>
                            <th>Sport</th>
                            <th className="text-right">Turnover</th>
                            <th className="text-right">Wins</th>
                            <th className="text-right">Bets Count</th>
                            <th className="text-right">Unresulted</th>
                            {/* <th className="text-right">Unique Clients</th> */}
                            <th className="text-right">Result</th>
                            <th className="text-right">Bonus Turnover</th>
                            <th className="text-right">Net Position</th>
                            <th className="text-right">Net Position %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.summary && props.summary.length > 0 ? props.summary.map( (sport, index) => {

                            if(! sport){
                                return null;
                            }

                            let betBacksTurnover = data_get(sport.betbacks, 'turnover');
                            let betBacksResult = data_get(sport.betbacks, 'result');

                            let singleSportTurnover = sport.single ?  data_get(sport.single, 'turnover') : 0;
                            let multiSportTurnover = !sport.single ?  data_get(sport, 'turnover') - betBacksTurnover : 0;

                            let singleSportResult = sport.single ?  data_get(sport.single, 'result') : 0;
                            let multiSportResult = !sport.single ?  data_get(sport, 'result') - betBacksResult : 0;

                            let sportTurnover = singleSportTurnover ? singleSportTurnover - betBacksTurnover : multiSportTurnover;
                            let sportResult = singleSportResult ? singleSportResult - betBacksResult : multiSportResult;

                            return <React.Fragment key={uniqueid()}>
                                <tr onClick={(e) => { handleCollapseTriggerClick(e); }} collapse-target={'sports-collapse-' + index}>
                                    <td className="product-name d-flex justify-content-start align-items-center">
                                        {sport.single || sport.betbacks ? <CollapseIndicator/> : null}
                                        <span className="ml-2">{sport.name}</span>
                                    </td>
                                    <td>{formatCurrency(sportTurnover)}</td>
                                    <td>{formatCurrency(sport.payout)}</td>
                                    <td>{sport.count && commaSeparate(sport.count)}</td>
                                    <td>{formatCurrency(sport.unresulted)}</td>
                                    {/* <td>{sport.unique_client_count}</td> */}
                                    <td>{formatCurrency(sportResult)}</td>
                                    <td>{formatCurrency(sport.bonus)}</td>
                                    <td>{formatCurrency(sportResult)}</td>
                                    <td>{formatPercentage(sportTurnover ? sportResult/sportTurnover : 0)}</td>
                                </tr>
                                {sport.single ?
                                    <tr className={'sports-collapse-' + index + ' hidden'}>
                                        <td className="text-left">Single</td>
                                        <td>{formatCurrency(singleSportTurnover)}</td>
                                        <td>{formatCurrency(sport.single.payout)}</td>
                                        <td>{sport.single.count}</td>
                                        <td>{formatCurrency(sport.single.unresulted)}</td>
                                        {/* <td>{sport.single.unique_client_count}</td> */}
                                        <td>{formatCurrency(singleSportResult)}</td>
                                        <td>{formatCurrency(sport.single.bonus)}</td>
                                        <td>{formatCurrency(singleSportResult)}</td>
                                        <td>{formatPercentage(singleSportTurnover ? singleSportResult/singleSportTurnover : 0)}</td>
                                    </tr> : null}
                                {sport.betbacks ?
                                    <tr className={'tr-betbacks sports-collapse-' + index + ' hidden'}>
                                        <td className="text-left">BetBacks</td>
                                        <td>{formatCurrency(data_get(sport.betbacks, 'turnover'))}</td>
                                        <td>{formatCurrency(sport.betbacks.payout)}</td>
                                        <td>{sport.betbacks.count}</td>
                                        <td>{formatCurrency(sport.betbacks.unresulted)}</td>
                                        {/* <td>{sport.betbacks.unique_client_count}</td> */}
                                        <td>{formatCurrency(sport.betbacks.result * -1)}</td>
                                        <td>{formatCurrency(sport.betbacks.bonus)}</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr> : null}
                            </React.Fragment>
                        }) : <tr><td colSpan="13" className="text-center py-1">No data found</td></tr>}
                    </tbody>
                </table>
            </div>
    );
};

export default SportBreakdown;

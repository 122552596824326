import React from 'react';
import {formatCurrency} from '../../utils/formatCurrency';
import {formatPercentage} from '../../utils/formatPercentage';
import {data_get} from '../../utils/data_get';
import {commaSeparate} from '../../utils/numbers'

const MultiSummary = (props) => {

    let racing = data_get(props.summary, 'racing', {});
    let sport = data_get(props.summary, 'sport', {});

    // Bet backs
    let racingBetBacks = data_get(data_get(racing, 'bet_types', {}), 'betbacks');
    let sportBetBacks = data_get(data_get(sport, 'bet_types', {}), 'betbacks');

    let betBacksTurnover = data_get(racingBetBacks, 'turnover', 0) + data_get(sportBetBacks, 'turnover', 0);
    let betBacksResult = data_get(racingBetBacks, 'result', 0) - data_get(sportBetBacks, 'result', 0);

    // Racing
    let racingTurnover = data_get(racing, 'turnover');
    let racingResult = data_get(racing, 'result');

    // Sport
    let sportTurnover = data_get(sport, 'turnover');
    let sportResult = data_get(sport, 'result');

    // Totals
    let turnover = racingTurnover + sportTurnover - betBacksTurnover;
    let result = racingResult + sportResult - betBacksResult;

    return <div className="p-2 mb-3">
        <div className="d-flex justify-content-around mb-2">
            <div className="figure">
                <p className="mb-1">Turnover:</p>
                <h5 className="figure-value-lg">{formatCurrency(turnover)}</h5>
            </div>
            <div className="figure">
                <p className="mb-1">Result:</p>
                <h5 className="figure-value-lg">{formatCurrency(result)}</h5>
            </div>
        </div>
        <div className="d-flex justify-content-around mb-2">
            <div className="figure">
                <p className="mb-1">Percentage:</p>
                <h5 className="figure-value-lg">{formatPercentage(turnover ? result / turnover : 0)}</h5>
            </div>
            <div className="figure">
                <p className="mb-1">Unresulted:</p>
                <h5 className="figure-value-lg">{formatCurrency(data_get(racing, 'unresulted') + data_get(sport, 'unresulted'))}</h5>
            </div>
        </div>
        <div className="d-flex justify-content-start mt-4">
            <p className="summary-figure-md mb-0 mr-4">Rejected: <span
                className="font-weight-bold">{commaSeparate(data_get(racing, 'rejected_count') + data_get(sport, 'rejected_count'))}</span></p>
            <p className="summary-figure-md mb-0">Bets Placed: <span
                className="font-weight-bold">{commaSeparate(data_get(racing, 'count') + data_get(sport, 'count'))}</span></p>
            {/* <p className="summary-figure-md mb-0">Unique Clients: <span
                className="font-weight-bold">{data_get(racing, 'unique_client_count') + data_get(sport, 'unique_client_count')}</span></p> */}
        </div>
    </div>;
}

export default MultiSummary;
import React from 'react';
import RacingSummary from "./RacingSummary";
// import ProductBreakDown from "./Racing/ProductBreakDown";
import uniqid from 'uniqid';
import TopClient from '../TopClients';

const RacingScoreCard = (props) => {

    let {periodKey, periodValue, meetingType, refresh, onClientLimitChange, onClientTypeChange, clientType, clientLimit, source} = props;

    return (
        <div className="score-card product-score-card">
            <RacingSummary title={props.title} breakdown={props.breakdown} summary={props.summary}/>
            <TopClient topClients={props.topClients}
                        type={clientType}
                        eventType="racing"
                        limit={clientLimit}
                        periodKey={periodKey}
                        periodValue={periodValue}
                        meetingType={meetingType}
                        source={source}
                        refresh={refresh}
                        onClientTypeChange={onClientTypeChange}
                        onClientLimitChange={onClientLimitChange} />
        </div>
    );
}

export default RacingScoreCard;
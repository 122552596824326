import React from 'react';
import {formatCurrency} from '../../utils/formatCurrency';
import {formatPercentage} from '../../utils/formatPercentage';
import {data_get} from '../../utils/data_get';
import {commaSeparate} from '../../utils/numbers'

const Summary = (props) => {

    let turnover  = data_get(props.summary, 'turnover');
    let result  = data_get(props.summary, 'result');

    let unresulted = data_get(props.summary, 'unresulted', 0);

    return <div className="p-3 mb-3">
            <div className="d-flex justify-content-around mb-2">
                <div className="figure">
                    <p className="mb-1">Turnover:</p>
                    <h5 className="figure-value-lg">{formatCurrency(turnover)}</h5>
                </div>
                <div className="figure">
                    <p className="mb-1">Result:</p>
                    <h5 className="figure-value-lg">{formatCurrency(result)}</h5>
                </div>
            </div>
            <div className="d-flex justify-content-around mb-2">
                <div className="figure">
                    <p className="mb-1">Percentage:</p>
                    <h5 className="figure-value-lg">{formatPercentage(turnover ? result/turnover : 0)}</h5>
                </div>
                <div className="figure">
                    <p className="mb-1">Unresulted:</p>
                    <h5 className="figure-value-lg">{formatCurrency(unresulted >= 0 ? unresulted : 0)}</h5>
                </div>
            </div>
            <div className="d-flex justify-content-start mt-4">
                <p className="summary-figure-md mb-0 mr-5">Rejected: <span className="font-weight-bold">{commaSeparate(data_get(props.summary, 'rejected_count'))}</span></p>
                <p className="summary-figure-md mb-0">Bets Placed: <span className="font-weight-bold">{commaSeparate(data_get(props.summary, 'count'))}</span></p>
                {/* <p className="summary-figure-md mb-0">Unique Clients: <span className="font-weight-bold">{data_get(props.summary, 'unique_client_count')}</span></p> */}
            </div>
        </div>;
}

export  default Summary;
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
*,
*::after,
*::before {
  box-sizing: border-box;
}

html, body{
    font-size: ${props => props.baseFontSize}px;
}

body {
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  transition: all 0.25s linear;
}

.gray-200 {
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-disabled {
    cursor: not-allowed;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #344175;
    background-color: #344175;
}

.custom-switch.custom-switch-xl {
    padding-bottom: 1rem;
      padding-left: 2.25rem;

    & .custom-control-label {
      padding-left: 2rem;
        padding-top: 0.5rem;

      &::before {
        border-radius: 0.2rem;
        height: 1.95rem;
        width: 3.5rem;
      }

      &::after {
        border-radius: 0.2rem;
        height: calc(1.95rem - 4px);
        width: calc(2rem - 4px);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(1.5rem);
    }
  }

p, table tr th, table tr td{
	font-size: .8rem !important;
}

.form-control, lable{
    font-size: .9rem !important;
}

.navbar{
  background-color: white;
}

.navbar-top{
	border-bottom: 3px solid #FC0E70;
	background-color: white;
	z-index: 999;
}

.navbar-top .navbar-brand{
	color:#000000;
	font-size: 16px;
	font-weight: bold;
}

.action-bar{
	box-shadow: 0 -1px 7px 1px #00000024;
	padding: 10px 30px !important;
}

.btn{
    font-size: .9rem !important;
    padding: .25rem 1.25rem !important;
}

.btn-xs{
    font-size: .8rem !important;
    padding: .13rem .8rem !important;
}

.btn-primary{
  	background-color: #F12371;
  	border-color: #F12371;
}

.btn-primary.btn-active{
	background-color: #ab124a !important;
	border-color: #ab124a !important;
}

.btn-secondary.active{
	background-color: #b7094c;
	border-color: #b7094c;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  	background-color: #ce145b !important;
  	border-color: #ce145b !important;
	outline: none;
	box-shadow: none;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #f54084;
    border-color: #eb4d89;
}

.btn-secondary.disabled, .btn-secondary:disabled{
    background-color: #7e88af !important;
    border-color: #7e88af !important;
}

.btn-primary-line{
    background-color: transparent;
    border: 1px solid #F12371;
    color: #F12371;
    outline: none !important;
}

.btn-secondary{
  	background-color: #344175;
  	border-color: #344175;
    outline: none;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus{
  	background-color: #2a345d !important;
  	border-color: #2a345d !important;
	outline: none !important;
	box-shadow: none;
}

.btn-secondary-line{
  	background-color: transparent;
  	border: 1px solid #344175;
	color: #344175;
    outline: none !important;
}


.btn-secondary.active, .btn-secondary-line.active{
	background-color: #344175 !important;
	border-color: #344175 !important;
	color: #ffffff;
	outline: none !important;
}

.btn-secondary-line:hover{
  	background-color: #344175 !important;
  	border-color: #344175 !important;
  	color: #ffffff;
	outline: none !important;
	box-shadow: none;
}

 .btn-secondary-line:focus{
    outline: none !important;
 }

.btn-white{
  	background-color: white;
  	border: 2px solid white;
	color: #000000;
	outline: none;
}

.btn-white:hover, .btn-white:focus{
  	background-color: #dfdfdf !important;
  	border-color: #dfdfdf !important;
	outline: none !important;
	box-shadow: none;
}

.btn-square{
    padding: .2rem .5rem !important;
}

.btn-auto-refresh:hover svg{
    fill: #ffffff;
}

.btn-auto-refresh svg{
    fill: #FFFFFF;
}

.input-xs{
    font-size: .8rem;
    padding: 1px 5px;
    height: calc(1.5em + 0.5rem + 1px);
}

.select-xs{
    font-size: .8rem !important;
    padding: .13rem .8rem !important;
    height: calc(1.5em + 0.38rem);
}

.bg-primary{
    background-color: #f40e70 !important;
}

.hidden{
    display: none !important;
}

.text-pink{
    color: #F12371 !important;
}

.size-13{
    width: 13px !important;
    height: 13px !important;
}

.react-datepicker__header{
    // background-color: #f12371 !important;
    background-color: #344175 !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    border-bottom: 1px solid #324079 !important;
}

.react-datepicker__header.react-datepicker-year-header{
    color: #ffffff;
}

.react-datepicker__triangle{
    // border-bottom-color: #f12371 !important;
    border-bottom-color: #344175 !important;
    left: 8px !important;
    border: 10px solid transparent;
    width: 0px !important;
    height: 2px !important;
    margin-left: -7px !important;
}

.react-datepicker__triangle::before{
    top: 0 !important;
    border-bottom-color: #344175 !important;
}

.react-datepicker__current-month, .react-datepicker__day-names .react-datepicker__day-name{
    color: #ffffff !important;
}

.react-datepicker__navigation{
    outline: none !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
    border-radius: 3px !important;
    // background-color: #f12371 !important;
    background-color: #344175 !important;
    outline: none;
}

.react-datepicker{
    border-radius: 3px !important;
    border-color: #344175 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #eef0f894;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding: 0 5px;
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
}

.login-container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-cards{
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 .67rem !important;
    position: relative;
}

.score-card-wrapper{
    padding: 0 .7rem;
 }

 .score-card-wrapper:first-child{
    padding-left: 1rem;
 }

 .score-card-wrapper:last-child{
    padding-right: 1rem;
 }


.score-card{
	background-color: white;
	border-radius: .5rem;
	padding: .8rem;
	box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05);
    position: relative;
}

.score-card-heading {
    color: #4f4e4e;
}

.score-card-content{
    position: relative;
}

.score-card .figure{
	width: 50%;
}

.product-score-card{
    min-width: 335px;
    margin: .27rem .25rem .5rem .25rem;
    flex: 1;
}

.sport-score-card{
    max-width: 500px !important;
}

.table-full-width{
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}

.table-figures thead tr th{
    padding-bottom: 10px;
}

.table-figures tbody td{
    padding: 5px 8px;
}

.table-figures .result-row{
    background-color: #344175 !important;
    padding: 5px 8px !important;
    color: #ffffff !important;
}

.table-figures tfoot td:first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.table-figures tfoot td:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.table-figures tbody td a{
    color: ${({ theme }) => theme.text};
}

.tr-betbacks{
    background-color: #f123703b !important;
}

.tr-bonus{
    background-color: #e1d1fb !important;
}

.tr-sub-total{
    background-color: #e9f7e1 !important;
    font-weight: 600;
}

.td-figure{
    width: 40%;
    text-align:right;
}

.td-figure-center{
    width: 40%;
    text-align:center;
}

.td-figure-red{
    color: rgb(231 49 104);
    background: #FED0DE;
    padding: 0.25rem 0.75rem;
    border-radius: 0.3rem;
    font-weight: bold;
}

.td-figure-green{
    color: rgb(80 190 28);
    background: #E9F7E1;
    padding: 0.25rem 0.75rem;
    border-radius: 0.3rem;
    width: 100%;
    font-weight: bold;
}

.figure-value-lg{
    font-size: 1.15rem;
    font-weight: 700;
}

.score-card .clients-table-wrapper{
	position: relative;
}

.score-card .clients-table-wrapper.has-scroll{
	box-shadow: inset 0 -10px 6px -10px #0000004a;
}

.date-range-filter{
    border: 1px solid #324079;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
}

.date-range-filter .react-datepicker-wrapper input{
    border: none;
    padding: 5px;
    outline: none;
    font-size: 0.875rem;
}

.date-range-filter .react-datepicker-wrapper input::placeholder{
    color: #3240799e !important;
}

.date-range-filter .addon{
    padding: 4px 8px;
    background-color: #344175;
    color: #ffffff;
    font-size: 0.875rem;
}

.date-range-filter .react-datepicker-wrapper:first-child input{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.date-range-filter .react-datepicker-wrapper:last-child input{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.product-breakdown {
    font-size: .8rem;
}

.product-breakdown .title{
    background-color: #344175;
    color: #ffffff;
    border-radius: 3px;
}

.product-breakdown .title button{
    border: none;
    background: transparent;
    outline: none;
    color: #ffffff;
    text-align: left;
}

.product-breakdown .title:hover{
    cursor: pointer;
}


.sports-breakdown{
    flex: 1;
}

.sports-breakdown table tr td{
    padding: 0.3rem;
    border-top: none;
}

.sports-breakdown table tr th{
    padding: .8rem 0.3rem;
    border: none;
}

.sports-breakdown table tr td, .sports-breakdown table tr th{
    font-size: .7rem;
}

.sports-breakdown table tbody tr td.product-name{
    background: #344175;
    color: #ffffff;
    width: 100px;
}

.sports-breakdown table tr td:not(.product-name){
    text-align: right;
}

.score-loading{
    position: absolute;
    z-index: 99999;
    width: 100%;
    background: #f5f6fa8c;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding-top: 1.5rem;
}

.score-page-loading{
    z-index: 999999;
}

.period-filters.show{
    display: flex !important;
}

.period-filters, .race-type-filter{
    position: relative;
}

.period-filters .overlay, .race-type-filter .overlay{
    position: absolute;
    background: #f1f1f199;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: 2px;
}

.period-filters .filter-buttons .period-button-wrapper{
    display: inline-flex;
}

.period-button-group{
    // position: relative;
    // display: inline-flex;
    // vertical-align: middle;
}

.period-button-group > .btn:not(:last-child):not(.dropdown-toggle), .period-button-group > .period-button-group:not(:last-child) > .btn{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.period-button-group > .btn:not(:first-child), .period-button-group > .period-button-group:not(:first-child) > .btn,
.period-button-group .btn-addon{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.period-filters .filter-buttons .period-button-group .btn-addon{
    padding: .25rem !important;
}

.summary-figure-md{
    font-size: .9rem !important;
}

.breakdown-title{
    min-width: 98px;
}

.divider{
    border-bottom: 1px solid #ececed;
    margin: 1.5rem -0.5rem;
}

.login-card{
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
    border: none;
    border-radius: .5rem;
}

.login-card .card-header{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.score-message{
    box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 2%), 0px 5px 5px -2px rgb(0 0 0 / 5%);
    border: 1px solid #0000000a;
    // min-width: 50%;
    // width: fit-content;
}

.score-chart-color{
    fill: var(--color, grey);
}

.chart-color-legend{
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 2px;
}

.channel-chart-wrapper{
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.channel-chart-wrapper .chart-title{
    position: absolute;
    font-size: 10px;
    font-weight: 500;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fade-in{
    animation: fadeIn 1s;
    transition: opacity 1s ease;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.spin{
    animation: spin var(--interval);
}

.collapse-indicator svg path{
    fill: #ffffff;
}

.collapse-indicator.right{
    transform: rotate(-90deg);
}

.score-container-xl{
    position: relative;
}

.Toastify__toast-container{
    z-index: 999999 !important;
}

.Toastify__toast--error{
    background-color: #F12371 !important;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 45%) !important;
    border-radius: 3px !important;
}

.score-stat-icon{
    background-color: #DE3D71;
    width: 50px;
    height: 50px;
    border-radius: 8px;
}

.score-stat-body{
    padding: 2.2rem !important
}

.score-stat-footer, .score-stat-footer .collapse-trigger{
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.score-stat-footer .collapse-trigger{
    background-color: #F1F3FF;
    min-height: 30px;
}

.score-stat-footer .collapse-trigger.open{
    border-radius: 0;
}

.score-stat-footer .collapse-trigger:hover{
    cursor: pointer;
    background-color: #ECEFFF;
}

.score-stat-footer p{
    font-weight: 500;
}

.score-stat-breakdown-table tr td{
    padding: 5px 8px;
}

// Client tags

.client-tag-list{
    margin: 0 2px 0 4px;
}

.client-tag{
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 2px;
    cursor: pointer;
    background: var(--tag-color) linear-gradient(#ffffff69, #fff0);
}

.__react_component_tooltip{
    font-size: 10px !important;
    padding: 5px 8px !important;
    border-radius: 4px !important;
}

/******* Media queries *******/

/* Filters */

@media (min-width: 1366px) {
    .score-card-wrapper{
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (max-width: 1366px) {
    .score-card-wrapper{
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .score-card-wrapper{
        padding: .7rem;
    }

}

@media (max-width: 1200px){
    .my-masonry-grid_column{
        flex: 1 1 50% !important;
    }
}

@media (min-width: 1200px){
	.score-container-xl{
		max-width: calc(100% - 100px) !important;
	}
}

@media (max-width: 768px){
    .score-card-wrapper{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .score-card-wrapper-sm .summary-figure-md{
        font-size: .8rem !important;
    }

    .score-card-wrapper-sm .btn-xs, .score-card-wrapper-sm .select-xs{
        padding: .1rem .3rem !important
    }
}

@media screen and (max-width: 576px) {
    .score-card-wrapper{
        flex: 0 0 100% !important;
        max-width: 100% !important;
   }

   .score-card-wrapper:first-child, .score-card-wrapper:last-child{
        padding: .7rem;
   }

    .btn {
        font-size: .7rem !important;
        padding: .1rem .7rem !important;
        outline: none;
    }

    .btn:focus, .btn:active{
        outline: none !important;
    }

    .btn-square svg{
        width: 10px;
    }

    p, table tr th, table tr td, .select-xs, .react-datepicker-wrapper input{
        font-size: .7rem !important;
    }

    .select-xs{
        padding: .13rem .5rem !important
    }

    .summary-figure-md{
        font-size: .8rem !important;
    }

    .score-card-wrapper:first-child{
        padding-left: .7rem !important;
    }

    .score-card{
        padding: .5rem !important;
    }

    .period-filters{
        width: 100%;
    }

    .period-filters .filter-buttons{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: 0 !important;
    }

    .date-range-filter-wrapper{
        width: 100%
    }

    .date-range-filter{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .date-range-filter .react-datepicker-wrapper{
        width: 100%;
    }

    .date-range-filter .react-datepicker-wrapper:first-child{
        border-bottom: 1px solid black;
    }

    .date-range-filter .react-datepicker-wrapper input{
        width: 100%;
    }

    .date-range-filter-buttons{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;
    }

    .date-range-filter-buttons button{
        flex: 1;
        margin-left: 0 !important;
    }

    .date-range-filter-buttons button:first-child{
        margin-right: 5px;
    }

    .devider {
        border-bottom: 1px solid #ececed;
        margin: 1.2rem 0;
    }

    .select2-container {
        width: 43% !important;
    }
}

/* Animations */
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.animate-spin{
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/* Select2 */
.select2-container {
    text-align: left;
    margin-right: 0.5rem !important;
}

.select2-results__option--highlighted {
    background-color: #344175 !important;
}

.select2-selection__clear {
    font-size: 19px;
}

`;

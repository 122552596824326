// Libraries
import $ from 'jquery';
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Container} from 'react-bootstrap';
import uniqid from 'uniqid';
import env from '../Environment';
import { cancellableToken } from '../common/ajax';

// Actions
import {getRacingSummary} from '../store/entitites/actions/RacingActions';
import {getSportsSummary} from "../store/entitites/actions/SportActions";
import {getMultiSummary} from "../store/entitites/actions/MultiActions";
import {fetchTopClients} from "../store/entitites/actions/ClientActions";
import {getOverviewReport} from "../store/entitites/actions/OverviewReportAction";
import {getRacingReport} from "../store/entitites/actions/RacingReportAction";
import {getSportReport} from "../store/entitites/actions/SportReportAction";
import {getMultiReport} from "../store/entitites/actions/MultiReportAction";

// Components
import PeriodFilter from '../components/Filters/PeriodFilter';
import ScoreCard from '../components/ScoreCard/ScoreCard';
import Summary from '../components/ScoreCard/Summary';
import Breakdown from '../components/ScoreCard/Breakdown';
import TopClient from '../components/ScoreCard/TopClients';
import OverviewBreakdown from "../components/ScoreCard/OverviewBreakdown";
import MultiBreakdown from "../components/ScoreCard/Multi/MultiBreakdown";
import Divider from "../components/Elements/Divider";
import RefreshButton from "../components/Common/RefreshButton";
import RefreshToggleButton from "../components/Common/RefreshToggleButton";
import FiltersButton from "../components/Common/FiltersButton";
import MultiSummary from "../components/ScoreCard/MultiSummary";
import Loading from "../components/Elements/Loading";

// Utils
import {current_date} from '../utils/date';
import {toFloat} from '../utils/numbers';
import {data_get} from "../utils/data_get";
import {download} from "../utils/download";
import {normalizeBreakdown, normalizeSummary} from '../utils/figures';
import {toast} from "react-toastify";

let source;
let timeout;

const HomePage = (props) => {

    if( ! localStorage.getItem('authHeader')){
        window.location = '/login';
    }

    /** State * */

    // Loading states
	const [isLoading, setIsLoading] = useState(false);
    const [fetchingRacingSummary, setFetchingRacingSummary] = useState(false);
    const [fetchingSportsSummary, setFetchingSportsSummary] = useState(false);
    const [fetchingMultiSummary, setFetchingMultiSummary] = useState(false);

    //- data
    const [racingSummary, setRacingSummary] = useState({});
    const [sportsSummary, setSportsSummary] = useState({});
    const [multiSummary, setMultiSummary] = useState({});
    const [overviewSummary, setOverviewSummary] = useState({});

    // -- breakdowns
    const [racingBreakdown, setRacingBreakdown] = useState([]);
    const [sportsBreakdown, setSportsBreakdown] = useState([]);

    //-- clients
    const [topClients, setTopClients] = useState([]);
    const [racingTopClients, setRacingTopClients] = useState([]);
    const [sportsTopClients, setSportsTopClients] = useState([]);
    const [multiTopClients, setMultiTopClients] = useState([])
    const [clientType, setClientType] = useState('winners');
    const [clientLimit, setClientLimit] = useState(10);
    const [raceClientType, setRaceClientType] = useState('winners');
    const [raceClientLimit, setRaceClientLimit] = useState(10);
    const [sportClientType, setSportClientType] = useState('winners');
    const [sportClientLimit, setSportClientLimit] = useState(10);
    const [multiClientType, setMultiClientType] = useState('winners');
    const [multiClientLimit, setMultiClientLimit] = useState(10);
    const client = useSelector(state => state.entities.client);

    //-- filters
    const [periodFilterKey, setPeriodFilterKey] = useState('date');
    const [periodFilterValue, setPeriodFilterValue] = useState(current_date());
    const [showFilters, setShowFilters] = useState(false);

    //-- auto refresh
    const refreshEvery = env('refreshEvery', 30) * 1000;
    const [refresh, setRefresh] = useState(false);

	useEffect(() => {
        clearTimeout(timeout);
        let fetchedData = true;

        if(! fetchedData){
            return null;
        }

        if(localStorage.getItem('autorefresh') === 'true') {
            setRefresh(true);
        } else {
            setRefresh(false);
        }

        loadPageData(periodFilterKey, periodFilterValue, clientType, clientLimit, raceClientType, raceClientLimit, sportClientType, sportClientLimit, multiClientType, multiClientLimit, client);

        return () => (fetchedData = false);
    }, [periodFilterKey, periodFilterValue, refresh, client]);

    useEffect(() => {
        if(refresh) {
            clearTimeout(timeout);
            setIsLoading(true)
            loadPageData(periodFilterKey, periodFilterValue, clientType, clientLimit, raceClientType, raceClientLimit, sportClientType, sportClientLimit, multiClientType, multiClientLimit, client);
        }
    }, [clientType, clientLimit, raceClientType, raceClientLimit, sportClientType, sportClientLimit, multiClientType, multiClientLimit]);

    /**
     * Fetch all page data
     *
     * @param {string} period
     * @param {string} value
     */
    const loadPageData = (period, value, type, limit, race_client_type, race_client_limit, sport_client_type, sport_client_limit, multi_client_type, multi_client_limit, client) => {

        if(!refresh) {
            setIsLoading(true);
        }

        // Check and cancel previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel('request');
        }

        source = cancellableToken.source();

        Promise.all([
            // Fetch racing summary
            getRacingSummary(periodFilterKey, periodFilterValue, null, source, client),

            // Fetch sports summary
            getSportsSummary(periodFilterKey, periodFilterValue, source, client),

            // Fetch multi summary
            getMultiSummary(periodFilterKey, periodFilterValue, null, source, client),

            // Fetch top clients
            fetchTopClients(null, type, limit, periodFilterKey, periodFilterValue, null, source)
                .then((clients) => {
                    setTopClients(clients);
            }),
            fetchTopClients(1, race_client_type, race_client_limit, periodFilterKey, periodFilterValue, null, source)
                .then((clients) => {
                    setRacingTopClients(clients);
                }),
            fetchTopClients(2, sport_client_type, sport_client_limit, periodFilterKey, periodFilterValue, null, source)
                .then((clients) => {
                    setSportsTopClients(clients);
                }),
            fetchTopClients(3, multi_client_type, multi_client_limit, periodFilterKey, periodFilterValue, null, source)
                .then((clients) => {
                    setMultiTopClients(clients);
                }),

        ]).then(function (responses) {
            clearTimeout(timeout);

            if(refresh) {
                timeout = setTimeout(() => {
                    loadPageData(period, value, type, limit, race_client_type, race_client_limit, sport_client_type, sport_client_limit, multi_client_type, multi_client_limit, client);
                }, refreshEvery);
            }

            let _racingSummary = responses[0];
            let _sportsSummary = responses[1];
            let _multiSummary = responses[2];

            if(!(_racingSummary && _sportsSummary && _multiSummary)) {
                return;
            }

            _racingSummary = normalizeSummary(_racingSummary, 'product_groups');
            setRacingSummary(_racingSummary);
            setRacingBreakdown(normalizeBreakdown(_racingSummary, 'product_groups'));

            setSportsSummary(normalizeSummary(_sportsSummary, 'sports'));
            setSportsBreakdown(normalizeBreakdown(_sportsSummary, 'sports'));

            setMultiSummary(_multiSummary);


            // Adjust multi turnover

            /**
             * Sadly OBject.assign() and {..{}} is not wokring(Ar least in Chrome).
             * Had to do clone _multiSummary in this ugly way.
             *
             * In overview breakdown,
             * turnover and result for multi should display after subtracting betcbaks, for multi racing and multi sport.
             *
             * So we clone _multiSummary and adjust turnover and result(racing, sport).
             *
             * Mathaka thiya ganda betbacks(turnover saha result), turnover saha result dekenma *ADU* karanda one.
             *
             */
            let overviewMultiSummary  = adjustOverviewMultiSummary(JSON.parse(JSON.stringify(_multiSummary)));

            // Normalize Overview summary
            let overview_summary = {};

            let keys = ['bonus', 'bonus_result', 'bonus_count','count','payout','rejected_count','result','turnover','unique_client_count','unresulted'];

            keys.map((key) => {
                overview_summary[key] = toFloat(_racingSummary[key]) + toFloat(_sportsSummary[key]) + toFloat(data_get(overviewMultiSummary.racing, key)) + toFloat(data_get(overviewMultiSummary.sport, key));
            });

            let totalBonus = data_get(_racingSummary, 'bonus') +
                data_get(_sportsSummary, 'bonus') +
                data_get(_multiSummary.racing, 'bonus') +
                data_get(_multiSummary.sport, 'bonus');

            let totalBonusResult = data_get(_racingSummary, 'bonus_result') +
                data_get(_sportsSummary, 'bonus_result') +
                data_get(_multiSummary.racing, 'bonus_result') +
                data_get(_multiSummary.sport, 'bonus_result');

            overview_summary['racing_breakdown'] = _racingSummary;
            overview_summary['sports_breakdown'] = _sportsSummary;
            overview_summary['multi_breakdown'] = overviewMultiSummary;
            overview_summary['bonus_breakdown'] = {
                turnover: totalBonus,
                result: totalBonusResult,
            };

            setOverviewSummary(overview_summary);

            setIsLoading(false);
        }).catch(e => {
           toast.error('Something went wrong!', {toastId: 'common-error-toast'});
        });
    }

    const adjustOverviewMultiSummary = (overviewMultiSummary) => {

        // Subtract betbacks turnover and result from, turnover and result(racing and sport)

        // Turnover
        overviewMultiSummary.racing['turnover'] = data_get(overviewMultiSummary.racing, 'turnover', 0) - data_get(overviewMultiSummary.racing.bet_types.betbacks, 'turnover', 0);
        overviewMultiSummary.sport['turnover'] = data_get(overviewMultiSummary.sport, 'turnover', 0) - data_get(overviewMultiSummary.sport.bet_types.betbacks, 'turnover', 0);

        // Result
        overviewMultiSummary.racing['result'] = data_get(overviewMultiSummary.racing, 'result', 0) - data_get(overviewMultiSummary.racing.bet_types.betbacks, 'result', 0);
        overviewMultiSummary.sport['result'] = data_get(overviewMultiSummary.sport, 'result', 0) - data_get(overviewMultiSummary.sport.bet_types.betbacks, 'result', 0);

        return overviewMultiSummary;
    }

    /**
     * Handle period change
     *
     * @param string period
     * @param string value
     */
    const onPeriodChange = (period, value) => {
        setIsLoading(true);
        setPeriodFilterKey(period);
        setPeriodFilterValue(value);
    }

    /**
     * Handle refresh click
     */
     const onRefreshClick = () => {
        setIsLoading(true);
        loadPageData(periodFilterKey, periodFilterValue, clientType, clientLimit, raceClientType, raceClientLimit, sportClientType, sportClientLimit, multiClientType, multiClientLimit, client);
    }

    /**
     * Handle auto refresh toggle
     */
    const handleAutoRefresh = (e) => {
        if(e.target.checked) {
            localStorage.setItem('autorefresh', true);
            setRefresh(true);
        } else {
            localStorage.setItem('autorefresh', false);
            setRefresh(false);
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
        }
    }

    /**
     * Handle client limit change
     */
    const handleClientLimitChange = (limit) => {
        setClientLimit(limit);
    }

    /**
     * Handle client type change
     */
    const handleClientTypeChange = (type) => {
        setClientType(type);
    }

    /**
     * Handle race client limit change
     */
    const handleRaceClientLimitChange = (limit) => {
        setRaceClientLimit(limit);
    }

    /**
     * Handle race client type change
     */
    const handleRaceClientTypeChange = (type) => {
        setRaceClientType(type);
    }

    /**
     * Handle sport client limit change
     */
    const handleSportClientLimitChange = (limit) => {
        setSportClientLimit(limit);
    }

    /**
     * Handle sport client type change
     */
    const handleSportClientTypeChange = (type) => {
        setSportClientType(type);
    }

    /**
     * Handle multi client limit change
     */
    const handleMultiClientLimitChange = (limit) => {
        setMultiClientLimit(limit);
    }

    /**
     * Handle sport client type change
     */
    const handleMultiClientTypeChange = (type) => {
        setMultiClientType(type);
    }

    /**
     * Handle download
     */
    const handleDownloadClick = (evt, alias) => {

        let btn = $(evt.target);

        if(alias === 'overview') {
            getOverviewReport(periodFilterKey, periodFilterValue, clientType, clientLimit)
            .then((response) => {
                download(response);
                btn.prop('disabled', false);
            })
        } else if(alias === 'racing-overview') {
            getRacingReport(periodFilterKey, periodFilterValue, raceClientType, raceClientLimit)
            .then((response) => {
                download(response);
                btn.prop('disabled', false);
            });
        } else if(alias === 'sport-overview') {
            getSportReport(periodFilterKey, periodFilterValue, sportClientType, sportClientLimit)
            .then((response) => {
                download(response);
                btn.prop('disabled', false);
            });
        } else if(alias === 'multi-overview') {
            getMultiReport(periodFilterKey, periodFilterValue, multiClientType, multiClientLimit)
            .then((response) => {
                download(response);
                btn.prop('disabled', false);
            });
        }
    }

    /**
     * Toggle display filters
     */
     const onFiltersClick = () => {
        setShowFilters( ! showFilters);
    }

	return (
		<Container className="score-container-xl py-4">

			{/* Filters */}
			<div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-sm-row flex-md-row">
				<PeriodFilter showFilters={showFilters} onChange={onPeriodChange} isLoading={isLoading}/>

                <div className="col px-0 d-flex justify-content-between justify-content-md-end justify-content-lg-end align-items-center flex-1">
                    <FiltersButton onClick={onFiltersClick}/>
                    <RefreshButton onClick={onRefreshClick} isLoading={isLoading}/>
                    <RefreshToggleButton onChange={handleAutoRefresh} checked={refresh} />
                </div>
			</div>

            <Loading isLoading={isLoading}/>


			{/* Score cards */}
			<div className={`row score-cards p-0 pb-3`}>

				<ScoreCard
                    size="sm"
                    title="Overview"
                    isLoading={isLoading}
                    content={[
                        <Summary key={uniqid()} summary={overviewSummary}/>,
                        <Divider key={uniqid()}/>,
                        <OverviewBreakdown key={uniqid} title="Overview Results" results={overviewSummary}/>,
                        !(client && client.id) ? <Divider key={uniqid()}/> : '',
                        <TopClient key="overview-client"
                                    topClients={topClients}
                                    type={clientType}
                                    limit={clientLimit}
                                    source={source}
                                    refresh={refresh}
                                    periodKey={periodFilterKey}
                                    periodValue={periodFilterValue}
                                    onClientTypeChange={handleClientTypeChange}
                                    onClientLimitChange={handleClientLimitChange} />
                        ]}
                    onDownloadClick={handleDownloadClick}
                    />

				<ScoreCard
                    size="sm"
                    title="Racing Overview"
                    isLoading={isLoading}
                    content={[
                        <Summary key={uniqid()} summary={racingSummary}/>,
                        <Divider key={uniqid()}/>,
                        <Breakdown  key={uniqid()} title="Racing Results" breakdown={racingBreakdown} summary={racingSummary}/>,
                        !(client && client.id) ? <Divider key={uniqid()}/> : '',
                        <TopClient  key="race-client"
                                    topClients={racingTopClients}
                                    eventType="racing"
                                    type={raceClientType}
                                    limit={raceClientLimit}
                                    source={source}
                                    refresh={refresh}
                                    periodKey={periodFilterKey}
                                    periodValue={periodFilterValue}
                                    onClientTypeChange={handleRaceClientTypeChange}
                                    onClientLimitChange={handleRaceClientLimitChange} />
                        ]}
                    onDownloadClick={handleDownloadClick}
                    />

				<ScoreCard
                    size="sm"
                    title="Sport Overview"
                    isLoading={isLoading}
                    content={[
                        <Summary key={uniqid()} summary={sportsSummary}/>,
                        <Divider key={uniqid()}/>,
                        <Breakdown key={uniqid()} title="Sport Results" breakdown={sportsBreakdown} summary={sportsSummary}/>,
                        !(client && client.id) ? <Divider key={uniqid()}/> : '',
                        <TopClient key="sport-client"
                                    topClients={sportsTopClients}
                                    eventType="sports"
                                    type={sportClientType}
                                    limit={sportClientLimit}
                                    source={source}
                                    refresh={refresh}
                                    periodKey={periodFilterKey}
                                    periodValue={periodFilterValue}
                                    onClientTypeChange={handleSportClientTypeChange}
                                    onClientLimitChange={handleSportClientLimitChange} />
                        ]}
                    onDownloadClick={handleDownloadClick}
                    />

                <ScoreCard
                    size="sm"
                    title="Multi Overview"
                    isLoading={isLoading}
                    content={[
                        <MultiSummary key={uniqid()} summary={multiSummary}/>,
                        <Divider key={uniqid()}/>,
                        <MultiBreakdown key={uniqid()} title="Multi Results" breakdown={multiSummary} summary={multiSummary}/>,
                        !(client && client.id) ? <Divider key={uniqid()}/> : '',
                        <TopClient key="multi-client"
                                    topClients={multiTopClients}
                                    eventType="multi"
                                    type={multiClientType}
                                    limit={multiClientLimit}
                                    source={source}
                                    refresh={refresh}
                                    periodKey={periodFilterKey}
                                    periodValue={periodFilterValue}
                                    onClientTypeChange={handleMultiClientTypeChange}
                                    onClientLimitChange={handleMultiClientLimitChange} />
                    ]}
                    onDownloadClick={handleDownloadClick}
                />
			</div>
		</Container>
	);
};

export default HomePage;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';

/**
 * Access for the routes
 */
const PrivateRoute = ({ component: Component, children, ...rest }) => {
	const RouteComponent = (
		<Route
			{...rest}
			render={(props) =>
				<Component {...props} />
			}
		/>
	);

	if (children) {
		return (
			<div>
				{children}
				{RouteComponent}
			</div>
		);
	}

	return RouteComponent;
};

PrivateRoute.propTypes = {
	/** The route path */
	path: PropTypes.string.isRequired,

	/** The component to render */
	component: PropTypes.func.isRequired,

	/** Require an exact match for the path */
	exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
	exact: false,
};

export default withRouter(PrivateRoute);

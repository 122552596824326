import React from 'react';
import uniqid from "uniqid";
import TopClient from "./TopClients";
import { useSelector } from 'react-redux';
let  slugify = require("underscore.string/slugify");

const ScoreCard = (props) => {

	let {clientEventType, clientType, clientLimit, clientPeriodKey, clientPeriodValue, clientMeetingType} = props;
	const client = useSelector(state => state.entities.client);

	return (
		<div className={'score-card-wrapper' + (props.size == 'sm' ? ' score-card-wrapper-sm' : '')}>
			<div className={'score-card' + (props.size == 'sm' ? ' p-2' : ' p-4')}>
				<div className="d-flex justify-content-between align-items-center score-card-heading px-2 pt-2 mb-3">
					<h6 className="font-weight-bold">{props.title}</h6>
					{ !(client && client.id) && props.onDownloadClick &&
						<button type="button" className="btn btn-secondary-line btn-sm" onClick={(e) => props.onDownloadClick(e, slugify(props.title))}>Download</button>
					}
				</div>
				<div className="score-card-content">
					{props.content}
				</div>
			</div>
		</div>
		);
};

export default ScoreCard;
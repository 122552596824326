import Controller from './controller';
import {CLEAR_AUTHENTICATED_USER} from '../application/applicationActionTypes';
import {MULTI_SUMMARY, RACING_SUMMARY, SPORTS_SUMMARY, SET_CLIENT, REMOVE_CLIENT} from './actions/ActionTypes';

let reducer = Controller.createReducer();

const initialState = {
	client: {},
};

/**
 *
 * Entity Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const entities = (state = initialState, action) => {
	state = reducer(state, action);

	switch (action.type) {
		case CLEAR_AUTHENTICATED_USER:
            return initialState;
		case RACING_SUMMARY:
			return {
				...state,
				racing_summary: action.payload
            };
		case SPORTS_SUMMARY:
			return {
				...state,
				sports_summary: action.payload
            };

        case MULTI_SUMMARY:
            return {
                ...state,
                multi_summary: action.payload
			};

		case SET_CLIENT:
			return {
				...state,
				client: action.payload
			}

		case REMOVE_CLIENT:
			return {
				...state,
				client: {}
			}

		default:
			return state;
	}
};

/**
 * Exports
 */
export default entities;

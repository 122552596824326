import React from 'react';
import RacingSummary from "./Racing/RacingSummary";
import ProductBreakDown from "./Racing/ProductBreakDown";
import uniqid from 'uniqid';

const ProductScoreCard = (props) => {
    return (
            <div className="score-card product-score-card">
                <RacingSummary title={props.title} breakdown={props.breakdown} summary={props.summary} noTotal={props.noTotal}/>

                {props.products ? props.products.map( (product) => {
                    return <ProductBreakDown key={uniqid()} product={product}/>;
                } ) : null}
            </div>
    );
}

export default ProductScoreCard;
// Libraries
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Container} from "react-bootstrap";
import env from '../Environment';
import { cancellableToken } from '../common/ajax';

// Actions
import {fetchTopClients} from "../store/entitites/actions/ClientActions";
import {getMultiSummary} from "../store/entitites/actions/MultiActions";

// Components
import PeriodFilter from "../components/Filters/PeriodFilter";
import SportBreakdown from "../components/ScoreCard/Sport/SportBreakdown";
import PageLoading from "../components/Elements/PageLoading";
import RefreshButton from "../components/Common/RefreshButton";
import RefreshToggleButton from "../components/Common/RefreshToggleButton";
import RacingSummary from "../components/ScoreCard/Racing/RacingSummary";
import TopClient from "../components/ScoreCard/TopClients";
import FiltersButton from "../components/Common/FiltersButton";

// Utils
import {current_date} from '../utils/date';
import {data_get} from "../utils/data_get";
import {normalizeBreakdown} from "../utils/figures";

let source;
let timeout;

const MultiPage = (props) => {

    if( ! localStorage.getItem('authHeader')){
        window.location = '/login';
    }

    /** State **/

    //-- Loading
    const [isLoading, setIsLoading] = useState(false);

    //-- Data
    const [racingSummary, setRacingSummary] = useState({});
    const [sportsSummary, setSportsSummary] = useState({});
    const [racingBreakdown, setRacingBreakdown] = useState([]);
    const [sportsBreakdown, setSportsBreakdown] = useState([]);

    //-- Clients
    const [multiTopClients, setMultiTopClients] = useState([]);
    const [clientType, setClientType] = useState('winners');
    const [clientLimit, setClientLimit] = useState(10);
    const client = useSelector(state => state.entities.client);

    // filters
    const [meetingType, setMeetingType] = useState(null);
    const [periodFilterKey, setPeriodFilterKey] = useState('date');
    const [periodFilterValue, setPeriodFilterValue] = useState(current_date());
    const [showFilters, setShowFilters] = useState(false);

    //-- auto refresh
    const refreshEvery = env('refreshEvery', 30) * 1000;
    const [refresh, setRefresh] = useState(false);

    /**
     * useEffect hook
     */
    useEffect(() => {
        clearTimeout(timeout);

        if(localStorage.getItem('autorefresh') === 'true') {
            setRefresh(true);
        } else {
            setRefresh(false);
        }

        loadPageData(periodFilterKey, periodFilterValue, meetingType, clientType, clientLimit, client);
    }, [periodFilterKey, periodFilterValue, refresh, client]);

    useEffect(() => {
        if(refresh) {
            clearTimeout(timeout);
            setIsLoading(true)
            loadPageData(periodFilterKey, periodFilterValue, meetingType, clientType, clientLimit, client);
        }
    }, [clientType, clientLimit]);

    /**
     * Fetch all page data
     *
     * @param {string} period
     * @param {string} value
     */
    const loadPageData = (period, value, meetingType, type, limit, client) => {

        if(!refresh) {
            setIsLoading(true);
        }

        // Check and cancel previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel('request');
        }

        source = cancellableToken.source();

        Promise.all([
            // Fetch multi summary
            getMultiSummary(periodFilterKey, periodFilterValue, meetingType, source, client),

            // Fetch top clients
            fetchTopClients(3, type, limit, periodFilterKey, periodFilterValue, meetingType, source)
                .then((clients) => {
                    setMultiTopClients(clients);
                }),

        ]).then(function (responses) {
            clearTimeout(timeout);
            if(refresh) {
                timeout = setTimeout(() => {
                    loadPageData(period, value, meetingType, type, limit, client);
                }, refreshEvery);
            }

            let _multiSummary = responses[0];

            if(!_multiSummary) {
                return;
            }

            let _racingSummary = data_get(_multiSummary, 'racing', {});
            let _racingBreakdown = normalizeBreakdown(_racingSummary, 'product_groups');

            setRacingSummary(_racingSummary);
            setRacingBreakdown(_racingBreakdown);

            let _sportsSummary = data_get(_multiSummary, 'sport', {});
            let _sportBreakdown =  normalizeBreakdown(_sportsSummary, 'sports');

            setSportsSummary(_sportsSummary);
            setSportsBreakdown(_sportBreakdown);

            setIsLoading(false);
        })
    }

    /**
     * Handle period change
     *
     * @param string period
     * @param string value
     */
    const onPeriodChange = (period, value) => {
        setIsLoading(true);
        setPeriodFilterKey(period);
        setPeriodFilterValue(value);
    }

    /**
     * Handle refresh click
     */
    const onRefreshClick = () => {
        setIsLoading(true);
        loadPageData(periodFilterKey, periodFilterValue, meetingType, clientType, clientLimit, client);
    }

    /**
     * Handle auto refresh toggle
     */
    const handleAutoRefresh = (e) => {
        if(e.target.checked) {
            localStorage.setItem('autorefresh', true);
            setRefresh(true);
        } else {
            localStorage.setItem('autorefresh', false);
            setRefresh(false);
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
        }
    }

    /**
     * Handle client limit change
     */
    const handleClientLimitChange = (limit) => {
        setClientLimit(limit);
    }

    /**
     * Handle client type change
     */
    const handleClientTypeChange = (type) => {
        setClientType(type);
    }

    /**
     * Toggle display filters
     */
    const onFiltersClick = () => {
        setShowFilters( ! showFilters);
    }

    return (
        <Container className="score-container-xl py-4">
            {/* Filters */}
            <div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-sm-row flex-md-row">
                <PeriodFilter onChange={onPeriodChange} isLoading={isLoading}/>
                <div className="col px-0 d-flex justify-content-between justify-content-md-end justify-content-lg-end align-items-center flex-1">
                    <FiltersButton onClick={onFiltersClick}/>
                    <RefreshButton onClick={onRefreshClick} isLoading={isLoading}/>
                    <RefreshToggleButton onChange={handleAutoRefresh} checked={refresh} />
                </div>
            </div>

            <div className="position-relative">
                <PageLoading isLoading={isLoading}/>

                {/* Score cards */}
                <div className="row justify-content-between align-items-start position-relative mx-0">


                    <div className="score-card product-score-card">
                        <RacingSummary title={'Racing'} breakdown={racingBreakdown} summary={racingSummary}/>
                    </div>

                    <div className="score-card product-score-card">
                        <RacingSummary title={'Sport'} breakdown={sportsBreakdown} summary={sportsSummary}/>
                    </div>
                    { !(client && client.id) &&
                        <div className="score-card product-score-card">
                            <TopClient  topClients={multiTopClients}
                                        eventType="multi"
                                        type={clientType}
                                        limit={clientLimit}
                                        periodKey={periodFilterKey}
                                        periodValue={periodFilterValue}
                                        source={source}
                                        refresh={refresh}
                                        onClientTypeChange={handleClientTypeChange}
                                        onClientLimitChange={handleClientLimitChange} />
                        </div>
                    }
                </div>

                <div className="row mx-0">
                    <SportBreakdown summary={data_get(sportsSummary, 'sports')}/>
                </div>
            </div>

        </Container>
    );
};

export default MultiPage;

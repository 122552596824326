import React from 'react';
import Spinner from "react-bootstrap/Spinner";

const PageLoading = (props) => {
    return (
        <div className={'score-loading score-page-loading ' + ( !props.isLoading ? 'hidden' : '')}>
            <Spinner animation="border" variant="pink" />
        </div>
    );
}

export default PageLoading;
import React, {useState} from 'react';
import Collapse from "react-bootstrap/Collapse";
import {formatPercentage} from '../../../utils/formatPercentage';
import {formatCurrency} from '../../../utils/formatCurrency';
import {getTurnover} from '../../../utils/figures';
import {data_get} from "../../../utils/data_get";

const ProductBreakDown = (props) => {

    const [open, setOpen] = useState(false);

    const product = props.product;

    let turnover = data_get(product, 'turnover');
    let result = data_get(product, 'result');

    return (
        <div className="mb-3 px-2 product-breakdown">
            <div className="title p-1">
                <button className="font-weight-bold btn-block" onClick={() => setOpen(!open)} aria-controls="product-breakdown-collapse" aria-expanded={open}>{product.name}</button>
            </div>
            <Collapse in={open}>
                <div id="product-breakdown-collapse">
                    <table className="table-full-width table-figures table-striped">
                        <tbody>
                        <tr>
                            <td>Turnover</td>
                            <td className="text-right">{formatCurrency(turnover)}</td>
                        </tr>
                        <tr>
                            <td>Free Turnover</td>
                            <td className="text-right">{formatCurrency(data_get(product, 'bonus'))}</td>
                        </tr>
                        <tr>
                            <td>Bet Wins</td>
                            <td className="text-right">{formatCurrency(data_get(product, 'payout'))}</td>
                        </tr>
                        <tr>
                            <td>Unresulted</td>
                            <td className="text-right">{formatCurrency(data_get(product, 'unresulted'))}</td>
                        </tr>
                        {/* <tr>
                            <td>Unique Clients</td>
                            <td className="text-right">{data_get(product, 'unique_client_count')}</td>
                        </tr> */}
                        <tr>
                            <td>Bets Count</td>
                            <td className="text-right">{data_get(product, 'count')}</td>
                        </tr>
                        <tr>
                            <td>Bonus Count</td>
                            <td className="text-right">{data_get(product, 'bonus_count')}</td>
                        </tr>
                        <tr>
                            <td>Rejected Count</td>
                            <td className="text-right">{data_get(product, 'rejected_count')}</td>
                        </tr>
                        <tr>
                            <td>Percentage</td>
                            <td className="text-right">{formatPercentage(turnover ? result/turnover : 0)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Collapse>
        </div>
    );
};

export default ProductBreakDown;

import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { current_date } from '../../utils/date';

const DateRange = (props) => {

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [toMinDate, setToMinDate] = useState(null);

    // TEMP
    const [month, setMonth] = useState(null);

    useEffect(() => {
        if(props.clearDates){
            setFromDate(null);
            setToDate(null);
            setToMinDate(null);
        }
    }, [props.clearDates]);

    const handleFromDateChange = (date) => {
        setFromDate(date);

        if(toDate && (new Date(fromDate) < new Date(toDate))){
            setToDate(date);
        }

        setToMinDate(date);
    }

    const handleToDateChange = (date) => {
        setToDate(date);
    }

    const handleApply = () => {
        if(props.handleChange){
            props.handleChange('range', {
                'from_date': fromDate ? moment(fromDate).format('YYYY-MM-DD') : null, 
                'to_date': toDate ? moment(toDate).format('YYYY-MM-DD') : null, 
            });
        }
    }

    const handleClearDates = () => {
        setFromDate(null);
        setToDate(null);
        setToMinDate(null);

        if(props.handleChange){
            props.handleChange('date', current_date());
        }
    }

    const handleMonthChange = (month) => {
        setMonth(month);
    }

    return <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row date-range-filter-wrapper">
        <div className="date-range-filter">
            <DatePicker dateFormat="dd/MM/yyyy" selected={props.fromDate ? props.fromDate : fromDate} onChange={handleFromDateChange} placeholderText="From"/>
            {/*<span className="addon">To</span>*/}
            <DatePicker dateFormat="dd/MM/yyyy" selected={props.toDate ? props.toDate : toDate} onChange={handleToDateChange} minDate={toMinDate}  placeholderText="To"/>
        </div>
        <div className="date-range-filter-buttons">
            <button className="btn btn-secondary btn-sm ml-1" onClick={handleApply} disabled={(!fromDate && !toDate)}>Apply</button>
            <button className="btn btn-secondary btn-sm ml-1" onClick={handleClearDates} >Clear</button>
        </div>
    </div>;
}

export default DateRange;
// Libraries
import React, {useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import uniqid from "uniqid";
import env from '../Environment';
import { cancellableToken } from '../common/ajax';

// Actions
import {getUniqueClientSummary} from "../store/entitites/actions/ClientActions";

// Components
import PeriodFilter from "../components/Filters/PeriodFilter";
import PageLoading from "../components/Elements/PageLoading";
import RefreshButton from "../components/Common/RefreshButton";
import RefreshToggleButton from "../components/Common/RefreshToggleButton";
import FiltersButton from "../components/Common/FiltersButton";

// Utils
import {current_date} from '../utils/date';
import StatBlock from '../components/Common/StatBlock';
import {data_get} from '../utils/data_get';

let source;
let timeout;

const UniqueClientsPage = (props) => {

    if( ! localStorage.getItem('authHeader')){
        window.location = '/login';
    }

    /** State **/

    //-- Loading
    const [isLoading, setIsLoading] = useState(false);

    //-- Data
    const [uniqueClientSummary, setUniqueClientSummary] = useState({});

    // filters
    const [meetingType, setMeetingType] = useState(null);
    const [periodFilterKey, setPeriodFilterKey] = useState('date');
    const [periodFilterValue, setPeriodFilterValue] = useState(current_date());
    const [showFilters, setShowFilters] = useState(false);

    //-- auto refresh
    const refreshEvery = env('refreshEvery', 30) * 1000;
    const [refresh, setRefresh] = useState(false);

    /**
     * useEffect hook
     */
    useEffect(() => {
        clearTimeout(timeout);

        if(localStorage.getItem('autorefresh') === 'true') {
            setRefresh(true);
        } else {
            setRefresh(false);
        }

        loadPageData(periodFilterKey, periodFilterValue, meetingType);
    }, [periodFilterKey, periodFilterValue, refresh]);

    /**
     * Fetch all page data
     *
     * @param {string} period
     * @param {string} value
     */
    const loadPageData = (period, value, meetingType) => {

        if(!refresh) {
            setIsLoading(true);
        }

        // Check and cancel previous pending requests
        if (typeof source != typeof undefined) {
            source.cancel('request');
        }

        source = cancellableToken.source();

        getUniqueClientSummary(periodFilterKey, periodFilterValue, meetingType, source)
            .then((response) => {

                clearTimeout(timeout);
                if(refresh) {
                    timeout = setTimeout(() => {
                        loadPageData(period, value, meetingType);
                    }, refreshEvery);
                }

                if(!response) {
                    return;
                }

                setIsLoading(false);
                setUniqueClientSummary(response);

            });

    }

    /**
     * Handle period change
     *
     * @param string period
     * @param string value
     */
    const onPeriodChange = (period, value) => {
        setIsLoading(true);
        setPeriodFilterKey(period);
        setPeriodFilterValue(value);
    }

    /**
     * Handle refresh click
     */
    const onRefreshClick = () => {
        setIsLoading(true);
        loadPageData(periodFilterKey, periodFilterValue);
    }

    /**
     * Handle auto refresh toggle
     */
    const handleAutoRefresh = (e) => {
        if(e.target.checked) {
            localStorage.setItem('autorefresh', true);
            setRefresh(true);
        } else {
            localStorage.setItem('autorefresh', false);
            setRefresh(false);
            if (typeof source != typeof undefined) {
                source.cancel('request');
            }
        }
    }

    /**
     * Toggle display filters
     */
    const onFiltersClick = () => {
        setShowFilters( ! showFilters);
    }

    return (
        <Container className="score-container-xl py-4">
            {/* Filters */}
            <div className="d-flex justify-content-between align-items-start mb-4 flex-column flex-sm-row flex-md-row">
                <PeriodFilter onChange={onPeriodChange} isLoading={isLoading}/>
                <div className="col px-0 d-flex justify-content-between justify-content-md-end justify-content-lg-end align-items-center flex-1">
                    <FiltersButton onClick={onFiltersClick}/>
                    <RefreshButton onClick={onRefreshClick} isLoading={isLoading}/>
                    <RefreshToggleButton onChange={handleAutoRefresh} checked={refresh} />
                </div>
            </div>

            <div className="position-relative">
                <PageLoading isLoading={isLoading}/>

                {/* Score cards */}
                <div className="row justify-content-between align-items-start position-relative mx-0">

                    <StatBlock icon="eye" title="Total Clients" value={uniqueClientSummary.count}/>
                    <StatBlock icon="racing" title="Racing Clients" value={data_get(uniqueClientSummary.racing, 'count')} breakdown={data_get(uniqueClientSummary.racing, 'breakdowns')}/>
                    <StatBlock icon="sport" title="Sport Clients" value={data_get(uniqueClientSummary.sport, 'count')} breakdown={data_get(uniqueClientSummary.sport, 'breakdowns')}/>
                    <StatBlock icon="multi" title="Multi Clients" value={data_get(uniqueClientSummary.multi, 'count')} breakdown={data_get(uniqueClientSummary.multi, 'breakdowns')}/>

                </div>
            </div>

        </Container>
    );
};

export default UniqueClientsPage;

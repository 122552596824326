import React from 'react';
import uniqid from 'uniqid';
import {data_get} from '../../utils/data_get';
import {formatPercentage} from '../../utils/formatPercentage';
import {formatCurrency} from '../../utils/formatCurrency';

const Breakdown = (props) => {
    
    const { results } = props;
    const breakdown = props.breakdown;
    const summary = props.summary;
    
    let totalTurnOver = 0;
	let totalResult = 0;

	return (
		<div className="p-2 mb-3">
			<table className="table-full-width table-figures table-striped">
				<thead>
					<tr>
						<th className="breakdown-title">{props.title}</th>
						<th className="td-figure">Turnover</th>
						<th className="td-figure">Result</th>
						<th className="td-figure">%</th>
					</tr>
				</thead>
				<tbody>
				{breakdown ? (
					breakdown.map((row, index) => {

                        if(!row){
                            return null;
                        }

						let turnover = data_get(row, 'turnover');
						let result = data_get(row, 'result');

                        if(row.name == 'BetBacks'){
                            totalTurnOver -= turnover;
                            totalResult -= result;
                        }else if( row.name !== 'Bonus'){
							totalTurnOver += turnover;
							totalResult += result;
						}

						let rowClass = row.name == 'BetBacks' ? 'tr-betbacks' : (row.name == 'Bonus' ? 'tr-bonus' : '');

						// Update result for BetBacks
						result = (row.name == 'BetBacks') ? -1 * result : result;

						return <tr className={rowClass} key={uniqid()}>
							<td>{row.name}</td>
							<td className="td-figure">{formatCurrency(turnover)}</td>
							<td className="td-figure">{formatCurrency(result)}</td>
							<td className="td-figure">{row.name !== 'Bonus' ? formatPercentage(turnover ? result/turnover : 0) : null}</td>
						</tr>
                     })
				    ) : (
                    <tr>
                        <td colSpan="4" className="text-center">
                            No Data Found
                        </td>
                    </tr>
				)}

                <tr className="result-row">
                    <td>Total</td>
                    <td className="td-figure">{ formatCurrency(totalTurnOver) }</td>
                    <td className="td-figure">{ formatCurrency(totalResult) }</td>
                    <td className="td-figure">{ formatPercentage(totalTurnOver ? totalResult/totalTurnOver : 0) }</td>
                </tr>
				</tbody>
			</table>
		</div>
	);
};

export default Breakdown;
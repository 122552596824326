import axios from 'axios';
import { get, post } from '../../common/ajax';
import { createAction } from '../index';
import { mergeEntities } from 'redux-entities-reducer';
import Controller from '../entitites/controller';

import { CLEAR_AUTHENTICATED_USER, SET_AUTHENTICATED_USER, AUTH_RESPONSE } from './applicationActionTypes';
import {redirectTo} from "../../utils/url";
import {LOGIN_ROUTE} from "../../constants/RouteConstants";

/**
 * Set the authenticated user
 *
 * @param userId
 * @returns {Object}
 */
export const setAuthenticatedUser = (userId) => {
	return createAction(SET_AUTHENTICATED_USER, userId);
};

/**
 * Clear auth user action
 *
 * @returns {Object}
 */
export const clearAuthenticatedUser = () => {
	return createAction(CLEAR_AUTHENTICATED_USER);
};

/**
 * Logout thunk action. Retrieves the stored OAuth token and issues a DELETE request to the OAuth endpoint
 *
 * @returns {function(*): *}
 */
export const logout = () => {
		localStorage.removeItem('authHeader');
        delete axios.defaults.headers.common['Authorization'];
        
        return true;
};

/**
 * Login thunk action. Issues a request with the user's email and password to the OAuth endpoint alongside
 * with an OAuth client and key.
 *
 * @param email
 * @param password
 * @returns {function(*): *}
 */
export const login = (email, password) => (dispatch) => {
    return get('/auth/login', {
        auth: {
            username: email,
            password: password
        }
    });
}

export const checkAuth = (token=null) => (dispatch) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token ? token : localStorage.getItem('authHeader')}`,
        }
    }

    return get('/auth/check', headers);
}

/**
 *
 * @returns {function(*): Promise<T>}
 */
export const fetchAuthenticatedUser = () => (dispatch) =>
	get('/auth/user').then((response) => {
		if (response) {
			dispatch(mergeEntities(Controller.normalize('user', [response.data.data]).entities));
			dispatch(setAuthenticatedUser(response.data.data.id));
		}
	});

/**
 * Set the access token in the Authorization header
 *
 * @param {*} token_type
 * @param {*} access_token
 */
export const setTokenHeader = (token_type, access_token) => {
	const authHeader = buildTokenHeader(token_type, access_token);
    localStorage.setItem('authHeader', authHeader)

	axios.defaults.headers.common['Authorization'] = authHeader;
};

/**
 * Build the token header string
 *
 * @param {*} token_type
 * @param {*} access_token
 */
export const buildTokenHeader = (token_type, access_token) => `${token_type} ${access_token}`;

export const redirectIfUnauthenticated = (status) => {
    if(status === 401){
        logout();
        redirectTo(LOGIN_ROUTE);
    }
}
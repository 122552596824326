import {get, wasCancelled} from '../../../common/ajax';
import {RACING_SUMMARY, SPORTS_SUMMARY} from "./ActionTypes";
import {current_date} from '../../../utils/date';
import {buildParams, redirectTo} from "../../../utils/url";
import {logout} from "../../application/applicationActions";
import {LOGIN_ROUTE} from "../../../constants/RouteConstants";
import {toast} from "react-toastify";

export const getSportsSummary = (filterKey, filterValue, source, client) => {

    let params = buildParams({filterKey: filterKey, filterValue: filterValue, client: client})

	return get('/stats/sport-summary', { cancelToken: source.token, params })
		.then( response => {
			return response.data ? response.data.data : {};
		})
		.catch((e) => {
		if (wasCancelled(e)) {
			return;
		}
		if(e.response.status == 401){
			logout();
			redirectTo(LOGIN_ROUTE);
		}
		toast.error('Error fetching sports summary', {toastId: 'sport-toast'});
	});
};

// TODO: Remove
export const fetchSportsBreakdown = (filterKey, filterValue) => (dispatch) => {
	// getSportsBreakDown(filterKey, filterValue).then((response) => {
	// 	dispatch({
	// 		type: SPORTS_SUMMARY,
	// 		payload: response ? response.data.data : {},
	// 	})
	// });
};

import React from 'react';
import moment from 'moment';

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getFileName = (string) => {
    if(string) {
        string.trim();
        let data = string.split(';');
        data = data[1].split('=');
        return data[1];
    }

    return moment().format('YYYY-MM-DD') + '.csv';

}
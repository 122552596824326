import {get, wasCancelled} from '../../../common/ajax';
import {buildParams} from "../../../utils/url";
import {redirectIfUnauthenticated} from "../../application/applicationActions";
import {toast} from "react-toastify";

export const getRacingSummary = (filterKey, filterValue, meetingType, source, client) => {

    let params = buildParams({filterKey: filterKey, filterValue: filterValue, meetingType: meetingType, client: client});

	return get('/stats/racing-summary', { cancelToken: source.token, params })
        .then((response) => {
            return response.data ? response.data.data : {};
        })
        .catch((e) => {
            if (wasCancelled(e)) {
                return;
            }
            redirectIfUnauthenticated(e.response.status);
            toast.error('Error fetching racing summary', {toastId: 'racing-toast'});
    });

};
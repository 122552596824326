import React from 'react';
import uniqid from 'uniqid';
import {formatPercentage} from '../../../utils/formatPercentage'
import {formatCurrency} from '../../../utils/formatCurrency';
import {data_get} from '../../../utils/data_get';
import {commaSeparate} from '../../../utils/numbers'

const RacingSummary = (props) => {

    let totalTurnOver = 0;
	let totalResult = 0;

	return (
		<div className="p-2 mb-3">
			<table className="table-full-width table-figures table-striped">
				<thead>
					<tr>
						<th>{props.title}</th>
						<th className="text-right">Turnover</th>
						<th className="text-right">Result</th>
						<th className="text-right">%</th>
					</tr>
				</thead>
				<tbody>
                    {props.breakdown && props.breakdown.length > 0 ? props.breakdown.map((row) => {

						if(!row){
							return null;
						}

						let turnover = data_get(row, 'turnover');
						let result = data_get(row, 'result');

						if(row.name === 'BetBacks'){
							totalTurnOver -= turnover;
							totalResult -= result;
						}else if( row.name !== 'Bonus'){
							totalTurnOver += turnover;
							totalResult += result;
						}

						let rowClass = row.name === 'BetBacks' ? 'tr-betbacks' : (row.name == 'Bonus' ? 'tr-bonus' : '');

						// Update result for BetBacks
						result = (row.name === 'BetBacks') ? -1 * result : result;

						return <tr className={rowClass} key={uniqid()}>
                            <td>{row.name}</td>
                            <td className="td-figure">{formatCurrency(turnover)}</td>
                            <td className="td-figure">{formatCurrency(result)}</td>
                            <td className="td-figure">{row.name !== 'Bonus' ? formatPercentage(turnover ? result/turnover : 0) : null}</td>
                        </tr>;
                    }) : <tr><td colSpan="4" className="text-center py-1">No records found</td></tr>}

					{ !props.noTotal ?
						<tr className={'result-row'}>
							<td>Total</td>
							<td className="td-figure">{formatCurrency(totalTurnOver)}</td>
							<td className="td-figure">{formatCurrency(totalResult)}</td>
							<td className="td-figure">{formatPercentage(totalTurnOver ? totalResult/totalTurnOver : 0)}</td>
						</tr>
					: null}
					<tr>
						<td>Unresulted</td>
						<td className="td-figure">{formatCurrency(data_get(props.summary, 'unresulted'))}</td>
						<td>Bets Placed</td>
						<td className="td-figure">{commaSeparate(data_get(props.summary, 'count'))}</td>
					</tr>
					<tr>
						<td>Rejected</td>
						<td className="td-figure">{commaSeparate(data_get(props.summary, 'rejected_count'))}</td>
						{/* <td>Unique Clients</td>
						<td className="td-figure">{data_get(props.summary, 'unique_client_count')}</td> */}
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default RacingSummary;

export const BASE_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const LOGOUT_ROUTE = '/logout';
export const DASHBOARD_ROUTE = '/dashboard';
export const RACING_ROUTE = '/racing';
export const SPORTS_ROUTE = '/sports';
export const MULTI_ROUTE = '/multi';
export const UNIQUE_CLIENTS_ROUTE = '/unique-clients';
export const BONUS_ROUTE = '/bonus';
export const CHANNEL_SUMMARY_ROUTE = '/channel-summary';

import { AUTH_RESPONSE, SET_AUTHENTICATED_USER, CLEAR_AUTHENTICATED_USER } from './applicationActionTypes';

const initialState = {
	authenticatedUser: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
        case AUTH_RESPONSE:
            return {
                ...state,
                auth: action.payload
            };
		case SET_AUTHENTICATED_USER:
			return {
				...state,
				authenticatedUser: action.payload,
			};

		case CLEAR_AUTHENTICATED_USER:
			return Object.assign({}, state, { authenticatedUser: initialState.authenticatedUser });

		default:
			return state;
	}
};

/**
 * Creates an action
 *
 * @param type
 * @param payload
 * @param options
 * @return {Object}
 */
export const createAction = (type, payload = undefined, options = {}) => {
	return Object.assign(
		{
			type,
			payload,
		},
		options,
	);
};


import React, {useState } from 'react';
import {Alert } from "react-bootstrap";

const ScoreAlert = function(props){

    const [show, setShow] = useState(props.show);

    return  (  show ? <Alert variant="warning" className="score-message" onClose={() => setShow(false)} dismissible>
                {props.children}
            </Alert> : null)
}

export default ScoreAlert;
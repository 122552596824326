import {get, wasCancelled} from '../../../common/ajax';
import {MULTI_SUMMARY} from './ActionTypes';
import {current_year} from '../../../utils/date';
import {buildParams, redirectTo} from "../../../utils/url";
import {logout} from "../../application/applicationActions";
import {LOGIN_ROUTE} from "../../../constants/RouteConstants";
import {toast} from "react-toastify";

export const getMultiSummary = (filterKey, filterValue, meetingType, source, client) => {

    let params = buildParams({filterKey: filterKey, filterValue: filterValue, meetingType: meetingType, client: client});

    return get('/stats/multi-summary', { cancelToken: source.token, params })
        .then(response => {
            return response.data ? response.data.data : {};
        })
        .catch((e) => {
        if (wasCancelled(e)) {
            return;
        }
        if(e.response && e.response.status == 401){
            logout();
            redirectTo(LOGIN_ROUTE);
        }
        toast.error('Error fetching multi summary', {toastId: 'multi-toast'});
    });
};
import { toast } from "react-toastify";
import { get, wasCancelled } from '../../../common/ajax';
import { LOGIN_ROUTE } from "../../../constants/RouteConstants";
import { buildParams, redirectTo } from "../../../utils/url";
import { data_get } from "../../../utils/data_get";
import { logout } from "../../application/applicationActions";

export const getSourceSummary = (filterKey, filterValue, source, client) => {

    let params = buildParams({filterKey: filterKey, filterValue: filterValue, client: client});

    return get('/reports/payload/source-summary', { cancelToken: source.token, params })
        .then(response => {

            if( ! response){
                return {};
            }

            let res = response.data ? response.data.data : {};

            // Exlicitly set message
            res['message'] = data_get(response.data.meta, 'message', null);

            return res;
        })
        .catch((e) => {
        if (wasCancelled(e)) {
            return;
        }
        if(e.response && e.response.status == 401){
            logout();
            redirectTo(LOGIN_ROUTE);
        }
        toast.error('Error fetching sources summary', {toastId: 'sources-toast'});
    });
};